import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { useProjects } from "../../../../context/ProjectsContext";
import { LazyLoadImage } from "react-lazy-load-image-component";
import arrow from "../../../../images/small-arrow.svg";

const ProjectWithImage = ({ project }) => {
  const { currentMode } = useProjects();
  const heightRef = useRef();
  const makeVisible = (e) => {
    const { currentTarget } = e;
    removeScreenHeight();
    setTimeout(() => {
      currentTarget.classList.remove("lazy-images");
    }, 100);
  };

  const removeScreenHeight = () => {
    heightRef.current.classList.remove("h-screen");
  };

  return (
    <div className="mb-12 lg:mb-36 h-screen" ref={heightRef}>
      <Link
        to={`/projects/${project.slug}`}
        className="group lg:grid lg:grid-cols-12 lg:gap-4 lg:gap-8 xl:gap-10"
      >
        <div className="placeholder bg-imgPlaceholder rounded lg:rounded-lg mb-4 lg:mb-0 lg:col-span-6 lg:order-2 group-hover:opacity-80">
          <LazyLoadImage
            src={`/files/${project.coverImage}/download`}
            className="lazy-images w-full rounded lg:rounded-lg duration-300"
            alt={project.title}
            onLoad={(e) => {
              makeVisible(e);
            }}
          />
        </div>
        <div className="lg:col-span-6 lg:order-1">
          <div
            className={`${
              currentMode === "dark-mode" ? "text-grey4" : "text-grey3"
            } text-s6 mb-2 lg:text-l4`}
          >
            {project.title}
          </div>
          <div
            className={`text-s4 lg:text-l1 ${
              currentMode === "dark-mode"
                ? "group-hover:text-grey4"
                : "group-hover:text-grey2"
            } duration-300 ease-out`}
          >
            {project.tagline}
          </div>
          <div
            className={`hidden md:flex opacity-0 mt-4 ${
              currentMode === "dark-mode" ? "text-grey4" : "text-grey3"
            } lg:text-l6 md:group-hover:opacity-100 duration-300 ease-out items-center`}
          >
            {project.tags.map((tag, index) => {
              return (
                <div key={tag} className="flex items-center">
                  <div>{tag}</div>
                  {project.tags.length !== index + 1 && (
                    <i className="px-2 text-center text-4px fa-solid fa-circle"></i>
                  )}
                </div>
              );
            })}
            <img
              src={arrow}
              alt="arrow"
              className="text-grey1 inline ml-1 opacity-0 group-hover:text-grey3 group-hover:opacity-100 group-hover:ml-3 duration-300"
            />
          </div>
        </div>
      </Link>
    </div>
  );
};

export default ProjectWithImage;
