import React from "react";
import { Link } from "react-router-dom";
import arrow from "../../../../images/small-arrow.svg";

const ProjectWithText = ({ project }) => {
  return (
    <div className="border-t border-grey3 py-6">
      <Link
        to={`/projects/${project.slug}`}
        className="group lg:grid lg:grid-cols-12 lg:gap-8 xl:gap-10 hover:text-grey3 hover:lg:text-grey3 duration-300 ease-out"
      >
        <div
          className={`text-s6 lg:text-l4 group-hover:lg:text-grey3 lg:col-span-6`}
        >
          {project.title}
          <img
            src={arrow}
            alt="arrow"
            className="text-grey1 inline ml-1 opacity-0 group-hover:text-grey3 group-hover:opacity-100 group-hover:ml-3 duration-300"
          />
        </div>
        <div className="text-s4 lg:text-l4 lg:col-span-3">
          {project.tagline}
        </div>
        <div className="hidden lg:block lg:text-l4 lg:col-span-3 text-right">
          {project.tags[0]}
        </div>
      </Link>
    </div>
  );
};

export default ProjectWithText;
