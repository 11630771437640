import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useArticles } from "../context/ArticlesContext";
import ContactBar from "../components/ContactBar";
import ArticleBodySlider from "../components/ArticleBodySlider";
import arrow from "../images/small-arrow-black.svg";
import axios from "axios";
import "react-slidedown/lib/slidedown.css";

const Notes = () => {
  const [aboutBlog, setAboutBlog] = useState();
  const [titleBlog, setTitleBlog] = useState();
  const [open, setOpen] = useState(false);
  const [offset, setOffset] = useState(6);
  const { publishedArticles, setPublishedArticles, articleCount } =
    useArticles();

  useEffect(() => {
    axios.get("/homeAndAbout").then((res) => {
      setAboutBlog(res.data[0].aboutBlog);
      setTitleBlog(res.data[0].titleBlog);
    });
  }, []);

  const makeVisible = (e) => {
    const { currentTarget } = e;
    removeScreenHeight(e.target);
    setTimeout(() => {
      currentTarget.classList.remove("lazy-images");
    }, 300);
  };

  const loadMore = () => {
    axios
      .get(`/articles/published?offset=${offset}&limit=6`)
      .then((res) => {
        setPublishedArticles(publishedArticles.concat(res.data.articles));
        setOffset(offset + 6);
      })
      .catch((err) => console.log(err));
  };

  const removeScreenHeight = (element) => {
    element.closest(".h-screen").classList.remove("h-screen");
  };

  return (
    <>
      <Helmet>
        <title>Notes</title>
      </Helmet>
      <div className="text-l1 min-h-screen">
        <div
          className={`grid text-s6 gap-4 pt-5 pb-14 md:pb-28 xs:gap-5 md:text-l6 md:grid-cols-12 lg:gap-8 xl:gap-10`}
        >
          <div className="md:col-span-6 md:col-start-4 blog-intro">
            <div
              className="text-s4 mb-4 md:text-l4"
              dangerouslySetInnerHTML={{ __html: titleBlog }}
            ></div>
            <div
              className="text-s6 md:text-l5 md:mb-0"
              dangerouslySetInnerHTML={{ __html: aboutBlog }}
            ></div>
          </div>
        </div>
        <div
          className={`grid white-page pb-20 lg:pb-36 px-4 xs:px-5 md:px-6 lg:px-10 xl:px-12 -mx-4 xs:-mx-5 md:-mx-6 lg:-mx-10 xl:-mx-12 text-s6 gap-4 xs:gap-5 md:text-l6 md:grid-cols-12 lg:gap-8 xl:gap-10`}
        >
          {publishedArticles.map((article) => {
            return (
              <React.Fragment key={article._id}>
                {article.headline.length < 70 ? (
                  <>
                    <div className="md:col-span-8 md:col-start-3 pt-28 h-screen">
                      <div className="placeholder bg-imgPlaceholder rounded lg:rounded-lg">
                        <LazyLoadImage
                          alt={article.headline}
                          src={`/files/${article.coverImage}/download?size=${
                            window.innerWidth * 1.5
                          }`}
                          className="lazy-images rounded w-full one duration-300"
                          onLoad={(e) => {
                            makeVisible(e);
                          }}
                        />
                      </div>
                    </div>
                    <div className="md:col-span-3 md:col-start-3 pt-6 text-s4 md:text-l4">
                      {article.headline}
                    </div>
                    <div className="md:col-span-5 md:col-start-6 pt-6 text-s6 md:text-l6">
                      {article.intro && <div className="">{article.intro}</div>}

                      {article.body && (
                        <ArticleBodySlider body={article.body} />
                      )}
                    </div>
                  </>
                ) : (
                  <div className="md:col-span-6 md:col-start-4 pt-28 h-screen">
                    <div className="bg-imgPlaceholder rounded lg:rounded-lg">
                      <LazyLoadImage
                        alt={article.headline}
                        src={`/files/${article.coverImage}/download?size=${window.innerWidth}`}
                        className="lazy-images rounded w-full two"
                        onLoad={(e) => {
                          makeVisible(e);
                        }}
                      />
                    </div>
                    <div className="pt-18 text-s4 md:text-l4">
                      {article.headline}
                    </div>

                    {article.intro && (
                      <div className="pt-12 text-s6 md:text-l6">
                        {article.intro}
                      </div>
                    )}
                    {article.body && <ArticleBodySlider body={article.body} />}
                  </div>
                )}
              </React.Fragment>
            );
          })}
          <div className="md:col-span-8 md:col-start-3"></div>
        </div>
        {publishedArticles.length < articleCount && (
          <div
            className="group cursor-pointer flex items-center justify-center text-s4 lg:text-l3 h-36 lg:h-50 bg-coral hover:bg-yellow -mx-4 xs:-mx-5 md:-mx-6 lg:-mx-10 xl:-mx-12 duration-300"
            onClick={() => loadMore()}
          >
            Load More
            <img
              src={arrow}
              alt="arrow"
              className="ml-6 group-hover:ml-9 duration-300 w-6 lg:w-9"
            />
          </div>
        )}
        <ContactBar />
      </div>
    </>
  );
};

export default Notes;
