import React from "react";
import Image from "../Image";

const Double = ({ contentArray, setContentArray, index }) => {
  return (
    <div className="flex mb-5 py-5 px-2 bg-white rounded w-full">
      <Image
        contentArray={contentArray}
        setContentArray={setContentArray}
        index={index}
        space="left"
        isDouble={true}
        className="w-1/2 py-0 mb-0"
      />
      <Image
        contentArray={contentArray}
        setContentArray={setContentArray}
        index={index}
        space="right"
        isDouble={true}
        className="w-1/2 py-0 mb-0"
      />
    </div>
  );
};

export default Double;
