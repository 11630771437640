import React from "react";

const Title = ({ project, className }) => {
  return (
    <div className={`mb-6 md:mb-40 lg:col-span-6 lg:col-start-4 ${className}`}>
      <div className="text-s5 w-full md:text-l5 mb-2 md:mb-4">
        {project.title}
      </div>
      <div className="text-s2 w-full md:text-l1">{project.tagline}</div>
    </div>
  );
};

export default Title;
