import React from "react";

const Sentence = ({ content }) => {
  return (
    <div className="mb-18 gap-4 md:grid-cols-12 md:mb-34 lg:gap-8 lg:col-span-10 lg:col-start-2 lg:grid xl:gap-10">
      <div className="text-s4 w-full md:text-l3 lg:col-span-4 mb-6">
        {content.one}
      </div>
      <div className="text-s6 w-full md:text-l5 lg:col-span-6 lg:col-start-7">
        {content.two}
      </div>
    </div>
  );
};

export default Sentence;
