import React from "react";

const Paragraph = ({ content, contentArray, index }) => {
  const nextItemIsParagraph = contentArray[index + 1]?.category === "paragraph";

  return (
    <div
      className={`${
        nextItemIsParagraph ? "mb-10 md:mb-14" : "mb-18 md:mb-34"
      } lg:col-span-6 lg:col-start-4`}
    >
      <div className="mb-6 text-s4 w-full md:text-l3">{content.one}</div>
      <div
        className="text-s6 w-full md:text-l5 paragraph-body"
        dangerouslySetInnerHTML={{ __html: content.two }}
      ></div>
    </div>
  );
};

export default Paragraph;
