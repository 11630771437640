import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    const logo = document.getElementById("logo");
    if (logo) {
      setTimeout(() => {
        logo.classList.remove("rotate-up");
      }, 700);
    }
    return function cleanup() {
      if (logo) {
        logo.classList.add("rotate-up");
      }
    };
  }, [pathname]);

  return null;
}
