import React from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const ProjectImage = ({
  images,
  setImages,
  index,
  projectImages,
  setProjectImages,
}) => {
  const navigate = useNavigate();
  const handleUpload = async (event) => {
    var formData = new FormData();
    formData.append("file", event.target.files[0]);
    formData.append("orientation", "landscape");

    axios
      .post("/files", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Token ${localStorage
            .getItem("token")
            .replace(/\"/g, "")}`,
        },
      })
      .then(async (res) => {
        let modifiedImage = [...images];
        modifiedImage[index].value = res.data._id;
        modifiedImage[index].orientation = await imageOrientation(
          `/files/${res.data._id}/download`
        );
        setImages(modifiedImage);
      })
      .catch((error) => navigate("/login"));
  };

  const imageOrientation = async (src) => {
    let img = new Image();
    img.src = src;
    await img.decode();

    if (img.naturalWidth > img.naturalHeight) {
      return "landscape";
    } else if (img.naturalWidth < img.naturalHeight) {
      return "portrait";
    } else {
      return "even";
    }
  };

  const handleChange = (e) => {
    let modifiedImage = [...images];
    modifiedImage[index].alt = e.target.value;

    setImages(modifiedImage);
  };

  const removeImage = (index) => {
    const imageArray = [...projectImages];
    imageArray.splice(index, 1);
    setProjectImages(imageArray);
  };

  return (
    <div className="mb-5 py-5 px-2 bg-white rounded relative">
      <div className="flex justify-between mb-3 text-s5 md:text-l6">
        <div>PROJECT IMAGE</div>
        <i
          className="fa-solid fa-xmark text-red-600 mr-3 cursor-pointer"
          onClick={() => removeImage(index)}
        ></i>
      </div>
      {images[index].value ? (
        <>
          <img
            src={`/files/${images[index].value}/download`}
            className="w-full"
            alt={images[index].alt}
          />
          <textarea
            placeholder="alt text"
            value={images[index].alt}
            onChange={(e) => handleChange(e, "two")}
            className="bg-warmGrey text-s5 w-full md:text-l5 mt-4"
          />
        </>
      ) : (
        <input
          type="file"
          accept="image/*"
          name="file"
          onChange={(e) => handleUpload(e)}
        />
      )}
    </div>
  );
};

export default ProjectImage;
