import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { useProjects } from "../context/ProjectsContext";
import ImageSwiper from "../components/ImageSwiper";
import { LazyLoadImage } from "react-lazy-load-image-component";
import InfiniteScroll from "react-infinite-scroll-component";

const Images = () => {
  const { publishedProjects } = useProjects();
  const [imageArray, setImageArray] = useState([]);
  const [isMobile, setIsMobile] = useState(document.body.clientWidth < 768);

  const checkIfMobile = () => {
    setIsMobile(document.body.clientWidth < 768);
  };

  const screensize = window.innerWidth / 3;

  window.addEventListener("resize", checkIfMobile);
  useEffect(() => {
    let imageItems = publishedProjects
      .map(({ slug, images }) =>
        images.map((image) => ({
          slug,
          image,
        }))
      )
      .flat()
      .sort((a, b) => a.image.order - b.image.order);
    if (!imageItems.length) {
      return;
    } else {
      setImageArray(imageItems);
    }
  }, [publishedProjects]);

  const loadMore = () => {
    setImageArray(imageArray.concat(imageArray));
  };

  const makeVisible = (e) => {
    const { currentTarget } = e;
    setTimeout(() => {
      currentTarget.classList.remove("lazy-images");
    }, 300);
  };

  if (!imageArray.length)
    return (
      <>
        <Helmet>
          <title>Images</title>
        </Helmet>
        <div></div>
      </>
    );

  return (
    <>
      <Helmet>
        <title>Images</title>
      </Helmet>
      {isMobile ? (
        <ImageSwiper items={imageArray} />
      ) : (
        <InfiniteScroll
          dataLength={imageArray.length}
          next={() => loadMore()}
          hasMore={true}
          loader={<h4>Loading...</h4>}
          className="md:grid md:grid-cols-3 md:gap-x-8 md:gap-y-16 xl:gap-x-24 xl:gap-y-28"
        >
          {imageArray.map((image, index) => {
            return (
              <Link
                to={`/projects/${image.slug}`}
                key={index}
                className="group aspect-square pt-8 relative"
              >
                <div
                  className={`placeholder bg-imgPlaceholder rounded lg:rounded-lg orientation-${image.image.orientation} absolute top-0 -translate-x-1/2 left-1/2`}
                >
                  <div
                    className={`w-full h-full text-grey1 flex flex-col justify-end p-4 md:p-6 opacity-0 text-s7 bg-cardBG rounded absolute top-0 -translate-x-1/2 left-1/2 md:text-l6 group-hover:opacity-100 duration-300`}
                  >
                    <div className="pb-4">{image.image.alt}</div>
                    <div>
                      View project
                      <i className="fa-solid fa-arrow-right inline ml-1 opacity-0 group-hover:opacity-100 group-hover:ml-2 duration-300 delay-300"></i>
                    </div>
                  </div>

                  <LazyLoadImage
                    alt={image.image.alt}
                    src={`/files/${
                      image.image.value
                    }/download?size=${Math.round(screensize * 2)}`}
                    className={`lazy-images opacity-100 w-full h-full rounded absolute top-0 -translate-x-1/2 left-1/2 group-hover:opacity-0 duration-300`}
                    onLoad={(e) => {
                      makeVisible(e);
                    }}
                  />
                </div>
              </Link>
            );
          })}
        </InfiniteScroll>
      )}
    </>
  );
};

export default Images;
