import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useArticles } from "../context/ArticlesContext";
import CircleLink from "../components/CircleLink";
import CoverImage from "../components/projects/edit/CoverImage";
import ProjectImage from "../components/projects/edit/ProjectImage";
import Outcome from "../components/projects/edit/Outcome";
import Checkbox from "../components/Checkbox";
import Select from "react-select";
import useToken from "../hooks/useToken";
import { RichTextEditor } from "@mantine/rte";

const ArticleEditor = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { register, handleSubmit, reset, watch } = useForm();
  const { articles, setArticles, getOrder } = useArticles();
  const [coverImage, setCoverImage] = useState("");
  const selectedArticle = articles.find((article) => article._id === id);
  const isNewArticle = !selectedArticle;
  const [error, setError] = useState("");
  const published = watch("published");
  const [bodyContent, setBodyContent] = useState("");
  useEffect(() => {
    if (selectedArticle) {
      setCoverImage(selectedArticle.coverImage);
      setBodyContent(selectedArticle.body);
      reset({
        headline: selectedArticle.headline,
        intro: selectedArticle.intro,
        body: selectedArticle.body,
        published: selectedArticle.published,
      });
    }
  }, [selectedArticle, reset]);

  const createArticle = (data) => {
    axios
      .post("/articles", changedArticle(data), {
        headers: {
          Authorization: `Token ${localStorage
            .getItem("token")
            .replace(/\"/g, "")}`,
        },
      })
      .then((res) => {
        let modifiedArticles = [...articles, res.data];
        setArticles(modifiedArticles);
        navigate("/admin/articles");
      })
      .catch((error) => {
        if (error.response.data === 403) {
          navigate("/login");
        } else {
          setError(error.response.data);
        }
      });
  };

  const changedArticle = (data) => {
    return {
      headline: data.headline,
      intro: data.intro,
      body: bodyContent,
      coverImage: coverImage,
      published: published,
    };
  };

  const updateArticle = (data) => {
    axios
      .put(`/articles/${id}`, changedArticle(data), {
        headers: {
          Authorization: `Token ${localStorage
            .getItem("token")
            .replace(/\"/g, "")}`,
        },
      })
      .then((res) => {
        let modifiedArticles = [...articles];
        let updatedProject = modifiedArticles.findIndex(
          (project) => project._id === id
        );
        modifiedArticles[updatedProject] = res.data;
        setArticles(modifiedArticles);
        navigate("/admin/articles");
      })
      .catch((error) => {
        if (error.response.data === 403) {
          navigate("/login");
        } else {
          setError(error.response.data);
        }
      });
  };

  const onSubmit = (data) => {
    setError("");
    if (isNewArticle) {
      createArticle(data);
    } else {
      updateArticle(data);
    }
  };

  const selectStyle = {
    control: (base) => ({
      ...base,
      border: 0,
      boxShadow: "none",
      marginBottom: "20px",
    }),
  };
  return (
    <div className="min-h-screen grid text-s6 pb-40 px-4 gap-4 xs:px-5 xs:gap-5 md:text-l6 md:px-6 md:grid-cols-12 lg:px-10 lg:gap-8 xl:px-12 xl:gap-10">
      <CircleLink
        path="/admin/articles"
        className="w-14 h-14 leading-14 text-s3"
      >
        <i className="fa-solid fa-arrow-left"></i>
      </CircleLink>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="col-span-8 col-start-3 relative"
      >
        <div className="w-full mt-6 text-s4 m-auto md:text-l4 items-center">
          <div className="mb-5">BLOG POST DETAILS</div>
          <CoverImage coverImage={coverImage} setCoverImage={setCoverImage} />
          <div className="mb-4 py-5 px-2 bg-white rounded">
            <div className="text-s5 md:text-l6">HEADLINE</div>
            <textarea
              {...register("headline")}
              className="px-3 py-2 my-2 bg-warmGrey text-s5 w-full md:text-l5 rounded"
              placeholder="Title of the article"
            />
          </div>
          <div className="mb-4 py-5 px-2 bg-white rounded">
            <div className="text-s5 md:text-l6">INTRO</div>
            <textarea
              {...register("intro")}
              className="px-3 py-2 my-2 bg-warmGrey text-s5 w-full md:text-l5 rounded"
              placeholder="Catchy intro"
            />
          </div>
          <div className="mb-4 py-5 px-2 bg-white rounded">
            <div className="text-s5 md:text-l6">BODY</div>
            <RichTextEditor
              value={bodyContent}
              onChange={setBodyContent}
              controls={[["link"]]}
              className="text-s5 md:text-l5"
            />
          </div>

          <div className="mb-4 p-5 bg-white rounded">
            <Checkbox ref={register} name="published" content="Published" />
          </div>
        </div>
        {error && (
          <div className="text-l4 absolute left-0 mt-5 text-red-600">
            {error}
          </div>
        )}
        <CircleLink
          type="submit"
          className="w-14 h-14 leading-14 text-s3 absolute right-0 mt-5"
        >
          <i className="fa-solid fa-floppy-disk"></i>
        </CircleLink>
      </form>
    </div>
  );
};

export default ArticleEditor;
