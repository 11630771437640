import React, { useState } from "react";
import { SlideDown } from "react-slidedown";
import plus from "../../images/add_icon.svg";

const ArticleBodySlider = ({ body }) => {
  const [open, setOpen] = useState(false);
  return (
    <SlideDown className="slide">
      {open ? (
        <div
          className="pt-12 cursor-pointer"
          dangerouslySetInnerHTML={{ __html: body }}
        ></div>
      ) : (
        <div
          className="pt-12 flex items-center cursor-pointer"
          onClick={() => setOpen(!open)}
        >
          <div className="mr-5">Read more</div>

          <img className="w-4 h-4" src={plus} alt="plus icon" />
        </div>
      )}
    </SlideDown>
  );
};

export default ArticleBodySlider;
