import React, { useEffect, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import CircleLink from "../components/CircleLink";
import axios from "axios";
import {
  DndContext,
  closestCenter,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  rectSortingStrategy,
} from "@dnd-kit/sortable";

import SortableItem from "../components/SortableItem";

const Clients = () => {
  const [clients, setClients] = useState([]);
  const [error, setError] = useState("");
  const sensors = useSensors(useSensor(PointerSensor));
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    axios
      .get("/clients")
      .then((res) => {
        setClients(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const itemIds = useMemo(() => {
    if (!clients) {
      return [];
    } else {
      return clients.map((client) => client?.order?.toString());
    }
  }, [clients]);

  const appendElement = () => {
    const newClient = {
      name: "",
      order: clients.length + 1,
    };

    setClients([...clients, newClient]);
  };

  const removeElement = (index, id) => {
    setLoading(true);
    if (id) {
      axios
        .delete(`/clients/${id}`, {
          headers: {
            Authorization: `Token ${localStorage
              .getItem("token")
              .replace(/\"/g, "")}`,
          },
        })
        .then((res) => {
          const elementArray = [...clients];
          elementArray.splice(index, 1);
          elementArray.map((item, index) => (item.order = index + 1));
          setClients(elementArray);
          setLoading(false);
        });
    } else {
      const elementArray = [...clients];
      elementArray.splice(index, 1);
      elementArray.map((item, index) => (item.order = index + 1));
      setClients(elementArray);
      setLoading(false);
    }
  };

  const handleChange = (e, index) => {
    setError("");
    let modifiedContent = [...clients];
    modifiedContent[index].name = e.target.value;
    setClients(modifiedContent);
  };

  const addLink = (
    <CircleLink
      onClick={() => appendElement()}
      className="fixed bottom-10 right-10 w-14 h-14 leading-14 text-s3"
    >
      <i className="fa-solid fa-plus"></i>
    </CircleLink>
  );

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      setClients((clients) => {
        const oldIndex = clients.findIndex(
          (item) => item.order.toString() === active.id
        );
        const newIndex = clients.findIndex(
          (item) => item.order.toString() === over.id
        );

        const movedArray = arrayMove(clients, oldIndex, newIndex);
        movedArray.map((item, index) => (item.order = index + 1));
        return movedArray;
      });
    }
  };

  const onSubmit = () => {
    setLoading(true);
    clients.forEach((client) => {
      axios
        .put("/clients", client, {
          headers: {
            Authorization: `Token ${localStorage
              .getItem("token")
              .replace(/\"/g, "")}`,
          },
        })
        .then((res) => {
          setClients(res.data);
          setLoading(false);
        })
        .catch((error) => {
          if (error.response.data === 403) {
            navigate("/login");
          } else {
            setError(error.response.data);
            setLoading(false);
          }
        });
    });
  };

  const handle = (
    <CircleLink
      type="button"
      onClick={() => {}}
      className="w-10 h-10 leading-10 text-s5 absolute top-0 -left-12 text-grey1 hover:text-white"
    >
      <i className="fa-solid fa-arrows-up-down-left-right"></i>
    </CircleLink>
  );

  if (!clients.length) return <div>No clients yet {addLink}</div>;
  if (loading) return <div>loading</div>;
  return (
    <div className="w-full text-center pb-40">
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <form onSubmit={() => onSubmit()}>
          <SortableContext items={itemIds} strategy={rectSortingStrategy}>
            {clients?.map((client, index) => {
              return (
                <SortableItem
                  key={client.order.toString()}
                  id={client.order.toString()}
                  className="relative w-1/2 m-auto mb-4"
                  handle={handle}
                  item={
                    <div className="text-s4 md:text-l4 m-auto">
                      <input
                        className="mb-2 text-s5 md:text-l5 rounded p-2 w-full"
                        placeholder="Client name"
                        value={clients[index].name}
                        onChange={(e) => handleChange(e, index)}
                      />
                      <CircleLink
                        type="button"
                        onClick={() => removeElement(index, client._id)}
                        className="w-10 h-10 leading-10 text-s5 absolute top-0 -right-12 text-red-600 hover:text-white"
                      >
                        <i className="fa-solid fa-xmark"></i>
                      </CircleLink>
                    </div>
                  }
                ></SortableItem>
              );
            })}
          </SortableContext>
          <CircleLink
            type="button"
            onClick={() => onSubmit()}
            className="fixed w-14 h-14 leading-14 text-s3 bottom-10 right-28"
          >
            <i className="fa-solid fa-floppy-disk"></i>
          </CircleLink>
        </form>
        {addLink}
        {error && (
          <div className="text-l4 absolute left-0 mt-5 text-red-600">
            {error}
          </div>
        )}
      </DndContext>
    </div>
  );
};

export default Clients;
