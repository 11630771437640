import React, { useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const CoverImage = ({ coverImage, setCoverImage }) => {
  const imageContainer = useRef();
  const navigate = useNavigate();

  const handleChange = (event, type) => {
    var formData = new FormData();
    formData.append("file", event.target.files[0]);
    axios
      .post("/files", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Token ${localStorage
            .getItem("token")
            .replace(/\"/g, "")}`,
        },
      })
      .then((res) => {
        setCoverImage(res.data._id);
      })
      .catch((error) => navigate("/login"));
  };

  return (
    <div className="mb-5 py-5 px-2 bg-white rounded" ref={imageContainer}>
      <div className="flex justify-between mb-3 text-s5 md:text-l6">
        <div>COVER IMAGE</div>
        {coverImage && (
          <div
            className="text-red-600 cursor-pointer mr-3"
            onClick={() => setCoverImage("")}
          >
            <i className="fa-solid fa-xmark"></i>
          </div>
        )}
      </div>
      {coverImage ? (
        <img
          src={`/files/${coverImage}/download?size=${
            imageContainer?.current?.offsetWidth * 2
          }`}
          className="w-full"
          alt="coverimage"
        />
      ) : (
        <input
          type="file"
          accept="image/*"
          name="file"
          onChange={(e) => handleChange(e, "one")}
        />
      )}
    </div>
  );
};

export default CoverImage;
