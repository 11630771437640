import React from "react";

const Intro = ({ contentArray, setContentArray, index }) => {
  const handleChange = (e, type) => {
    let modifiedContent = [...contentArray];
    modifiedContent[index].value[type] = e.target.value;
    setContentArray(modifiedContent);
  };

  return (
    <>
      <div className="mb-5 py-5 px-2 bg-white rounded">
        <div className="mb-3 text-s5 md:text-l6">INTRO</div>
        <textarea
          placeholder="write something"
          value={contentArray[index].value?.one}
          onChange={(e) => handleChange(e, "one")}
          className="bg-warmGrey text-s4 w-full md:text-l3 "
          data-no-dnd="true"
        />
      </div>
    </>
  );
};

export default Intro;
