import React, { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useProjects } from "../context/ProjectsContext";
import useToken from "../hooks/useToken";

const Login = () => {
  const { setLoggedIn } = useProjects();
  const [error, setError] = useState();
  const navigate = useNavigate();
  const { setToken } = useToken();
  const [password, setPassword] = useState();

  async function loginUser(password) {
    return axios
      .post("/login", { password: password })
      .then((res) => res.data.token)
      .catch((error) => {
        setError(error.response.data);
        setToken(error.response.data);
      });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = await loginUser(password);
    if (!token) {
      return;
    }
    setToken(token);
    setLoggedIn(true);
    navigate("/admin");
  };

  return (
    <div className="max-w-sm m-auto text-center text-s4 md:text-l4">
      <div className="mb-8">Hi James, write your password here:</div>
      <form className="relative" onSubmit={handleSubmit}>
        <input
          type="password"
          className="rounded p-2 w-full"
          onChange={(e) => setPassword(e.target.value)}
        />

        <button
          type="submit"
          className="absolute top-2 right-4 hover:text-grey3 duration-300"
        >
          <i className="fa-solid fa-arrow-right-to-bracket"></i>
        </button>
        {error && (
          <div className="text-s5 md: text-l5 text-red-600 mt-2">
            Nope, that was not correct
          </div>
        )}
      </form>

      <div className="text-s5 text-l5 mt-8">
        Not James? You'll have more luck{" "}
        <Link to={`/`} className="underline cursor-pointer">
          here
        </Link>
      </div>
    </div>
  );
};

export default Login;
