import React, { useState, useMemo, useEffect } from "react";
import axios from "axios";
import { useProjects } from "../../context/ProjectsContext";
import { useNavigate } from "react-router-dom";
import useToken from "../../hooks/useToken";
import CircleLink from "../../components/CircleLink";

import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  rectSortingStrategy,
} from "@dnd-kit/sortable";

import SortableItem from "../../components/SortableItem";

const Outcomes = () => {
  const { publishedProjects } = useProjects();
  const navigate = useNavigate();
  const { token } = useToken();
  const outcomeArray = publishedProjects
    .map(({ _id, title, coverImage, outcomes }) =>
      outcomes.map((outcome) => ({ outcome, _id, title }))
    )
    .flat()
    .sort((a, b) => {
      return a.outcome.order - b.outcome.order;
    });

  const [items, setItems] = useState(outcomeArray);

  const itemIds = useMemo(() => items.map((item) => item.outcome._id), [items]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      setItems((items) => {
        const oldIndex = items.findIndex(
          (item) => item.outcome._id === active.id
        );
        const newIndex = items.findIndex(
          (item) => item.outcome._id === over.id
        );

        arrayMove(items, oldIndex, newIndex).map((item, index) => {
          const project = publishedProjects.find(
            (proj) => proj._id === item._id
          );
          const outcome = project.outcomes.find(
            (out) => out._id === item.outcome._id
          );

          outcome.order = index + 1;
          return axios
            .put(`/api/projects/${item._id}`, project, {
              headers: {
                Authorization: `Token ${localStorage
                  .getItem("token")
                  .replace(/\"/g, "")}`,
              },
            })
            .catch((error) => navigate("/login"));
        });

        return arrayMove(items, oldIndex, newIndex);
      });
    }
  };
  if (!itemIds.length)
    return (
      <div className="flex items-center">
        <CircleLink
          path="/admin/reorder"
          className="w-14 h-14 leading-14 text-s3"
        >
          <i className="fa-solid fa-arrow-left"></i>
        </CircleLink>
        <div className="ml-10">NO OUTCOMES YET</div>
      </div>
    );
  return (
    <div className="px-4 pb-8 xs:px-5 md:px-6 lg:px-10 xl:px-12 min-h-screen md:col-span-9 bg-warm-grey">
      <CircleLink
        path="/admin/reorder"
        className="w-14 h-14 leading-14 text-s3"
      >
        <i className="fa-solid fa-arrow-left"></i>
      </CircleLink>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <div className="lg:grid lg:grid-cols-3 lg:gap-8 xl:gap-10">
          <SortableContext items={itemIds} strategy={rectSortingStrategy}>
            {items.map((item) => (
              <SortableItem
                key={item.outcome._id}
                id={item.outcome._id}
                item={item.outcome.value}
                className="group p-8 group-hover:text-blue-600 lg:text-l3 bg-white"
              />
            ))}
          </SortableContext>
        </div>
      </DndContext>
    </div>
  );
};

export default Outcomes;
