import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { selectedClients } from "../utils/projects";
import { Link } from "react-router-dom";
import { useProjects } from "../context/ProjectsContext";
import ContactBar from "../components/ContactBar";
import axios from "axios";

const About = () => {
  const { setPageSpecificClasses } = useProjects();
  const [about, setAbout] = useState();
  const [clients, setClients] = useState([]);
  const [presses, setPresses] = useState([]);
  const [pressProjectsArray, setPressProjectsArray] = useState();
  const [isMobile, setIsMobile] = useState(document.body.clientWidth < 768);

  useEffect(() => {
    axios.get("/clients").then((res) => {
      setClients(res.data);
    });
    axios.get("/homeAndAbout").then((res) => {
      setAbout(res.data[0].about);
    });
    axios.get("/press").then((res) => {
      setPresses(res.data);
      let projectNames = res.data.map((item) => item.project);
      let uniqueProjects = projectNames.filter((item, position) => {
        return projectNames.indexOf(item) == position;
      });
      setPressProjectsArray(uniqueProjects);
    });
  }, []);

  useEffect(() => {
    setPageSpecificClasses("white-page");

    return function cleanup() {
      setPageSpecificClasses("");
    };
  }, [setPageSpecificClasses]);

  const checkIfMobile = () => {
    setIsMobile(document.body.clientWidth < 768);
  };

  const pressesPerProject = (project) => {
    return presses.filter((press) => press.project === project);
  };

  window.addEventListener("resize", checkIfMobile);

  return (
    <>
      <Helmet>
        <title>About</title>
      </Helmet>
      <div
        className={`grid text-s6 gap-4 xs:gap-5 md:text-l6 md:grid-cols-12 lg:gap-8 xl:gap-10`}
      >
        <div className="md:col-span-6 md:col-start-4">
          <div className="text-s4 mb-4 md:text-l4">About me</div>
          <div className="text-s6 md:text-l5 mb-36 md:mb-0 about-intro">
            <div
              className="mb-5"
              dangerouslySetInnerHTML={{ __html: about }}
            ></div>
          </div>
          {!isMobile && (
            <ContactBar className="!bg-transparent" copyright={false} />
          )}
          <div className="border-t border-grey1 border-solid mb-20">
            <div className="my-5">Selected Clients and Agencies</div>
            {clients?.map((client) => {
              return (
                <div key={client.name}>
                  {client.name}
                  <br />
                </div>
              );
            })}
          </div>
          <div className="border-t border-grey1 border-solid mb-20">
            <div className="my-5">Selected Press</div>

            {pressProjectsArray?.map((project) => {
              return (
                <div key={project} className="mb-5">
                  <div className="text-grey3 mb-2">{project}</div>
                  {pressesPerProject(project).map((press) => {
                    return (
                      <div className="mb-1" key={press.name}>
                        <a
                          href={`${press.link}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          class="hover:border-b hover:border-underlineHover"
                        >
                          {press.name}
                        </a>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <ContactBar />
    </>
  );
};

export default About;
