import React from "react";
import "./Moon.scss";

const Moon = () => {
  return (
    <div id="mode-button-moon">
      <svg
        width="60px"
        height="60px"
        viewBox="0 0 60 60"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          id="Page-1"
          stroke="none"
          strokeWidth="2"
          fill="none"
          fillRule="evenodd"
        >
          <g id="Group" fill="#FFFFFF" fillRule="nonzero">
            <circle id="Oval" cx="30" cy="30" r="30"></circle>
            <g
              id="Moon"
              transform="translate(21.000000, 21.000000)"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
            >
              <path
                d="M18.9999808,10.3559 C18.8335808,12.1564 18.1577808,13.8724 17.0517808,15.303 C15.9456808,16.7335 14.4551808,17.8195 12.7543808,18.4338 C11.0536808,19.0481 9.21318078,19.1653 7.44828078,18.7718 C5.68338078,18.3783 4.06698078,17.4902 2.78838078,16.2116 C1.50978078,14.933 0.621680776,13.3166 0.228180776,11.5517 C-0.165319224,9.7868 -0.0481192242,7.9463 0.566180776,6.2456 C1.18048078,4.5448 2.26648078,3.0543 3.69698078,1.9482 C5.12758078,0.8422 6.84358078,0.1664 8.64408078,0 C7.58998078,1.4262 7.08268078,3.1834 7.21458078,4.952 C7.34638078,6.7206 8.10878078,8.3831 9.36278078,9.6372 C10.6168808,10.8913 12.2793808,11.6536 14.0479808,11.7854 C15.8165808,11.9173 17.5737808,11.41 18.9999808,10.3559 Z"
                id="MoonCenter"
              ></path>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
};

export default Moon;
