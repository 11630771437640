import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Video = ({ contentArray, setContentArray, index }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleChange = (event, type) => {
    setLoading(true);
    var formData = new FormData();
    formData.append("file", event.target.files[0]);
    axios
      .post("/files", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Token ${localStorage
            .getItem("token")
            .replace(/\"/g, "")}`,
        },
      })
      .then((res) => {
        let modifiedContent = [...contentArray];
        modifiedContent[index].value[type] = res.data._id;
        setContentArray(modifiedContent);
        setLoading(false);
      })
      .catch((error) => navigate("/login"));
  };

  return (
    <div className="mb-5 py-5 px-2 bg-white rounded">
      {loading ? (
        <>
          <i className="fa-solid fa-spinner fa-spin"></i>
          <span className="ml-2">
            Loading and compressing, this takes a while...{" "}
          </span>
        </>
      ) : contentArray[index].value?.one ? (
        <video className="w-full" controls>
          <source
            src={`/files/${contentArray[index].value?.one}/download`}
            className="w-full"
            type="video/mp4"
          />
        </video>
      ) : (
        <input
          type="file"
          accept="video/mp4,video/x-m4v,video/*"
          name="file"
          onChange={(e) => handleChange(e, "one")}
        />
      )}
    </div>
  );
};

export default Video;
