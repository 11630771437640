import React from "react";
import ContactLink from "../ContactLink";
import { useProjects } from "../../context/ProjectsContext";

const ContactBar = ({ text, children, className, copyright = true }) => {
  const currentYear = new Date().getFullYear();
  const { currentMode } = useProjects();

  return (
    <div
      className={`footer md:flex md:justify-between pt-6 lg:pt-12 pb-10 lg:pb-40 -mx-4 xs:-mx-5 md:-mx-6 lg:-mx-10 xl:-mx-12 px-4 xs:px-5 md:px-6 lg:px-10 xl:px-12 ${
        currentMode === "dark-mode" ? "bg-grey2" : "bg-white"
      } ${className}`}
    >
      <div className="flex pb-10 lg:pb-0">
        <ContactLink path="mailto:jamescuddy@gmail.com">Email</ContactLink>
        <ContactLink path="https://www.linkedin.com/in/jcuddy/">
          LinkedIn
        </ContactLink>
      </div>

      {copyright && (
        <div
          className={`text-s6 lg:text-s6 ${
            currentMode === "dark-mode" ? "text-white" : "text-grey3"
          }`}
        >
          © {currentYear}
        </div>
      )}
    </div>
  );
};

export default ContactBar;
