import React, { useEffect } from "react";
import { reorderTypes } from "../utils/projects";
import { Link } from "react-router-dom";
import CircleLink from "../components/CircleLink";
import useToken from "../hooks/useToken";
import { useNavigate } from "react-router-dom";

const Reorder = () => {
  const navigate = useNavigate();
  const { token } = useToken();

  return (
    <>
      <CircleLink path="/admin" className="w-14 h-14 leading-14 text-s3">
        <i className="fa-solid fa-arrow-left"></i>
      </CircleLink>
      <div className="text-l2 min-h-screen pl-80 bg-warmGrey">
        <ul className="list-none">
          {reorderTypes.map((type) => {
            return (
              <li key={type.tag}>
                <Link
                  className="col-span-7 col-start-3 h-14 hover:text-grey3 duration-300 mb-20 leading-loose"
                  to={`/admin/reorder/${type.tag}`}
                >
                  {type.name}
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
};

export default Reorder;
