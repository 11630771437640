import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { ProjectsProvider } from "./context/ProjectsContext";
import { AdminProvider } from "./context/AdminContext";
import { ArticlesProvider } from "./context/ArticlesContext";
import Layout from "./components/Layout";
import ScrollToTop from "./components/ScrollToTop";
import Home from "./pages/Home";
import Projects from "./pages/Projects";
import Images from "./pages/Images";
import Outcomes from "./pages/Outcomes";
import About from "./pages/About";
import Notes from "./pages/Notes";
import Login from "./pages/Login";
import Admin from "./pages/Admin";
import Clients from "./pages/Clients";
import Press from "./pages/Press";
import Articles from "./pages/Articles";
import ArticleEditor from "./pages/ArticleEditor";
import HomeAndAbout from "./pages/HomeAndAbout";
import Reorder from "./pages/Reorder";
import ReorderImages from "./pages/reorder/Images";
import ReorderOutcomes from "./pages/reorder/Outcomes";
import ReorderProjectsWithImages from "./pages/reorder/ProjectsWithImages";
import ReorderProjectsWithText from "./pages/reorder/ProjectsWithText";
import ProjectEditor from "./pages/ProjectEditor";
import ContentEditor from "./pages/ContentEditor";
import ProjectDetails from "./pages/ProjectDetails";
import { HelmetProvider } from "react-helmet-async";

function App() {
  return (
    <BrowserRouter>
      <HelmetProvider>
        <ScrollToTop />
        <ProjectsProvider>
          <ArticlesProvider>
            <Layout>
              <Routes>
                <Route index element={<Home />} />
                <Route path="/projects" element={<Projects />} />
                <Route path="/projects/:id" element={<ProjectDetails />} />
                <Route path="/images" element={<Images />} />
                <Route path="/outcomes" element={<Outcomes />} />
                <Route path="/about" element={<About />} />
                <Route path="/notes" element={<Notes />} />
                <Route
                  path="/login"
                  element={
                    <AdminProvider>
                      <Login />
                    </AdminProvider>
                  }
                />
                <Route
                  path="/admin"
                  element={
                    <AdminProvider>
                      <Admin />
                    </AdminProvider>
                  }
                />
                <Route
                  path="/admin/new-project"
                  element={
                    <AdminProvider>
                      <ProjectEditor />
                    </AdminProvider>
                  }
                />
                <Route
                  path="/admin/new-article"
                  element={
                    <AdminProvider>
                      <ArticleEditor />
                    </AdminProvider>
                  }
                />
                <Route
                  path="/admin/reorder"
                  element={
                    <AdminProvider>
                      <Reorder />
                    </AdminProvider>
                  }
                />
                <Route
                  path="/admin/reorder/projects-with-image"
                  element={
                    <AdminProvider>
                      <ReorderProjectsWithImages />
                    </AdminProvider>
                  }
                />
                <Route
                  path="/admin/reorder/projects-with-text"
                  element={
                    <AdminProvider>
                      <ReorderProjectsWithText />
                    </AdminProvider>
                  }
                />
                <Route
                  path="/admin/reorder/images"
                  element={
                    <AdminProvider>
                      <ReorderImages />
                    </AdminProvider>
                  }
                />
                <Route
                  path="/admin/reorder/outcomes"
                  element={
                    <AdminProvider>
                      <ReorderOutcomes />
                    </AdminProvider>
                  }
                />
                <Route
                  path="/admin/clients"
                  element={
                    <AdminProvider>
                      <Clients />
                    </AdminProvider>
                  }
                />
                <Route
                  path="/admin/homeAndAbout"
                  element={
                    <AdminProvider>
                      <HomeAndAbout />
                    </AdminProvider>
                  }
                />
                <Route
                  path="/admin/press"
                  element={
                    <AdminProvider>
                      <Press />
                    </AdminProvider>
                  }
                />
                <Route
                  path="/admin/articles"
                  element={
                    <AdminProvider>
                      <Articles />
                    </AdminProvider>
                  }
                />
                <Route
                  path="/admin/articles/:id"
                  element={
                    <AdminProvider>
                      <ArticleEditor />
                    </AdminProvider>
                  }
                />
                <Route path="/admin/:id/content" element={<ContentEditor />} />
                <Route path="/admin/:id" element={<ProjectEditor />} />
                <Route path="/*" element={<Navigate replace to="/" />} />
              </Routes>
            </Layout>
          </ArticlesProvider>
        </ProjectsProvider>
      </HelmetProvider>
    </BrowserRouter>
  );
}

export default App;
