import React from "react";
import { Helmet } from "react-helmet-async";
import { useProjects } from "../context/ProjectsContext";
import ProjectWithImage from "../components/projects/overview/ProjectWithImage";
import ProjectWithText from "../components/projects/overview/ProjectWithText";
import ContactBar from "../components/ContactBar";

const Projects = () => {
  const { publishedProjects } = useProjects();

  const projectsWithImage = publishedProjects
    .filter((project) => project.publishedOnProjectsImage.value)
    .sort((a, b) => {
      return (
        a.publishedOnProjectsImage.order - b.publishedOnProjectsImage.order
      );
    });
  const projectsWithText = publishedProjects
    .filter((project) => project.publishedOnProjectsText.value)
    .sort((a, b) => {
      return (
        a.publishedOnProjectsImage.order - b.publishedOnProjectsImage.order
      );
    });
  if (!publishedProjects.length)
    return (
      <>
        <Helmet>
          <title>Projects</title>
        </Helmet>
        <div></div>
      </>
    );
  return (
    <>
      <Helmet>
        <title>Projects</title>
      </Helmet>
      {projectsWithImage?.map((project) => {
        return <ProjectWithImage key={project._id} project={project} />;
      })}
      <div className="block h-12"></div>

      <div className="mb-18 lg:mb-36">
        {projectsWithText?.map((project) => {
          return <ProjectWithText key={project._id} project={project} />;
        })}
      </div>

      <ContactBar />
    </>
  );
};

export default Projects;
