import React from "react";
import "./Sun.scss";

const Sun = () => {
  return (
    <div id="mode-button-sun">
      <svg
        width="60"
        height="60"
        viewBox="0 0 60 60"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Group">
            <circle
              id="Oval"
              fillRule="nonzero"
              cx="30"
              cy="30"
              r="30"
            ></circle>
            <g
              id="Sun"
              transform="translate(19.000000, 19.000000)"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
            >
              <path
                d="M11.0002,16 C13.7617,16 16.0004,13.7614 16.0004,11 C16.0004,8.23858 13.7617,6 11.0002,6 C8.23866,6 6,8.23858 6,11 C6,13.7614 8.23866,16 11.0002,16 Z"
                id="SunCenter"
                fillRule="nonzero"
              ></path>
              <line x1="11" y1="0" x2="11" y2="2" id="Path"></line>
              <line x1="11" y1="20" x2="11" y2="22" id="Path"></line>
              <line
                x1="3.2207"
                y1="3.21973"
                x2="4.64075"
                y2="4.63973"
                id="Path"
              ></line>
              <line
                x1="17.3613"
                y1="17.3604"
                x2="18.7814"
                y2="18.7804"
                id="Path"
              ></line>
              <line x1="0" y1="11" x2="2.00007" y2="11" id="Path"></line>
              <line x1="20" y1="11" x2="22.0001" y2="11" id="Path"></line>
              <line
                x1="3.2207"
                y1="18.7804"
                x2="4.64075"
                y2="17.3604"
                id="Path"
              ></line>
              <line
                x1="17.3613"
                y1="4.63973"
                x2="18.7814"
                y2="3.21973"
                id="Path"
              ></line>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
};

export default Sun;
