import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import axios from "axios";

const Home = () => {
  const [homeText, setHomeText] = useState("");
  useEffect(() => {
    axios.get("/homeAndAbout").then((res) => {
      setHomeText(res.data[0].home);
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>James Cuddy</title>
      </Helmet>
      <div className="grid grid-cols-12 home-full-height">
        <div
          className="col-span-12 text-s3 md:text-home md:col-span-8"
          dangerouslySetInnerHTML={{ __html: homeText }}
        ></div>
      </div>
    </>
  );
};

export default Home;
