import React, { useState, useEffect } from "react";
import CircleLink from "../components/CircleLink";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { RichTextEditor } from "@mantine/rte";

const Paragraph = ({}) => {
  const [texts, setTexts] = useState({});
  const [error, setError] = useState();
  const navigate = useNavigate();

  const sections = [
    { label: "home", text: "Home" },
    { label: "about", text: "About" },
    { label: "titleBlog", text: "Title Blog" },
    { label: "aboutBlog", text: "About Blog" },
  ];

  useEffect(() => {
    axios.get("/homeAndAbout").then((res) => {
      setTexts(res.data);
    });
  }, []);

  const handleChange = (e, section) => {
    let modifiedContent = [...texts];
    modifiedContent[0][section] = e;
    setTexts(modifiedContent);
  };

  const updateTexts = () => {
    axios
      .put(`/homeAndAbout/${texts[0]._id}`, texts[0], {
        headers: {
          Authorization: `Token ${localStorage
            .getItem("token")
            .replace(/\"/g, "")}`,
        },
      })
      .then((res) => {
        setTexts(res);
        navigate("/");
      })
      .catch((error) => {
        if (error.response.data === 403) {
          navigate("/login");
        } else {
          setError(error.response.data);
        }
      });
  };

  if (!texts.length) return <div>Loading</div>;

  return (
    <div className="relative pb-28">
      {sections.map((section) => {
        return (
          <div className="mb-5 mt py-5 px-2 bg-white rounded">
            <div key={section["label"]} className="mb-2">
              <div className="mb-3 text-s5 md:text-l6">{section["text"]}</div>
              <RichTextEditor
                className="mb-2 bg-warmGrey text-s4 w-full md:text-l4 rounded p-2"
                placeholder={`Type ${section["text"]} text`}
                value={texts[0][section["label"]]}
                onChange={(e) => handleChange(e, section["label"])}
                rows={4}
                controls={[["link"]]}
              />
            </div>
          </div>
        );
      })}

      {error && (
        <div className="text-l4 absolute left-0 mt-5 text-red-600">{error}</div>
      )}
      <CircleLink
        onClick={() => updateTexts()}
        className="absolute bottom-10 right-10 w-14 h-14 leading-14 text-s3"
      >
        <i className="fa-solid fa-floppy-disk"></i>
      </CircleLink>
    </div>
  );
};

export default Paragraph;
