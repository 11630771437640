import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

export const ArticlesContext = React.createContext();

export const ArticlesProvider = ({ children }) => {
  const navigate = useNavigate();
  const [articles, setArticles] = useState([]);
  const [publishedArticles, setPublishedArticles] = useState([]);
  const [articleCount, setArticleCount] = useState("");

  const getOrder = (type) => {
    return (
      articles.filter((project) => project[type].value === true).length + 1
    );
  };

  useEffect(() => {
    axios
      .get("/articles")
      .then((res) => {
        setArticles(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    axios
      .get(`/articles/published?offset=0&limit=6`)
      .then((res) => {
        setArticleCount(res.data.articleCount);
        setPublishedArticles(res.data.articles);
      })
      .catch((err) => console.log(err));
  }, []);

  if (!articles) return <div>FETCHING ARTICLES</div>;

  return (
    <ArticlesContext.Provider
      value={{
        articles,
        setArticles,
        publishedArticles,
        setPublishedArticles,
        articleCount,
        getOrder,
      }}
    >
      {children}
    </ArticlesContext.Provider>
  );
};

export const useArticles = () => {
  const context = useContext(ArticlesContext);

  if (context === undefined) {
    throw new Error("useArticles must be used within a ArticlesProvider");
  }

  return context;
};
