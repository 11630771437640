import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useProjects } from "./ProjectsContext";
import axios from "axios";
import Login from "../pages/Login";
import useToken from "../hooks/useToken";

export const AdminContext = React.createContext();

export const AdminProvider = ({ children }) => {
  const { loggedIn, setLoggedIn } = useProjects();
  const { token } = useToken();
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      navigate("/login");
    }
  }, [token]);

  return <AdminContext.Provider value={{}}>{children}</AdminContext.Provider>;
};

export const useAdmin = () => {
  const context = useContext(AdminContext);

  if (context === undefined) {
    throw new Error("useAdmin must be used within a AdminProvider");
  }

  return context;
};
