import React, { useState, useEffect } from "react";
import NavBar from "../navigation/NavBar";
import { useProjects } from "../../context/ProjectsContext";
import ModeWrapper from "../modeButtons/ModeWrapper";
import useToken from "../../hooks/useToken";

const Layout = ({ children }) => {
  const { token } = useToken();
  const [isMobile, setIsMobile] = useState(document.body.clientWidth < 768);
  const { pageSpecificClasses, currentMode } = useProjects();

  const checkIfMobile = () => {
    setIsMobile(document.body.clientWidth < 768);
  };

  window.addEventListener("resize", checkIfMobile);

  return (
    <div className={`${currentMode} ${pageSpecificClasses}`}>
      <div
        className={`${currentMode} text-textMode px-4 xs:px-5 md:px-6 lg:px-10 xl:px-12 min-h-screen`}
      >
        <div className="available-width block h-25vh min-h-40 xs:min-h-44 md:min-h-25vh md:max-h-60 bg-transparent">
          <NavBar isMobile={isMobile} />
        </div>
        {isMobile && (
          <ModeWrapper className="fixed bottom-5 right-4 xs:bottom-5 xs:right-5 z-10" />
        )}

        {children}
      </div>
    </div>
  );
};

export default Layout;
