import React, { useRef } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Double = ({ content }) => {
  const imageContainerOne = useRef();
  const imageContainerTwo = useRef();

  const makeVisible = (e) => {
    const { currentTarget } = e;
    removeScreenHeight();
    setTimeout(() => {
      currentTarget.classList.remove("lazy-images");
    }, 300);
  };

  const removeScreenHeight = () => {
    imageContainerOne.current.classList.remove("h-screen");
    imageContainerTwo.current.classList.remove("h-screen");
  };

  return (
    <div className="md:grid md:grid-cols-10 gap-4 md:gap-8 xl:gap-10 mb-18 md:mb-34 w-full md:col-span-10 md:col-start-2 relative">
      <div
        className="bg-imgPlaceholder rounded lg:rounded-lg md:col-span-5 h-screen"
        ref={imageContainerOne}
      >
        <LazyLoadImage
          alt={content.left.two}
          src={`/files/${content.left.one}/download?size=${
            imageContainerOne?.current?.offsetWidth * 2
          }`}
          className="lazy-images w-full rounded lg:rounded-lg  mb-6 md:mb-0 duration-300"
          onLoad={(e) => {
            makeVisible(e);
          }}
        />
      </div>
      <div
        className="bg-imgPlaceholder rounded lg:rounded-lg md:col-span-5 h-screen"
        ref={imageContainerTwo}
      >
        <LazyLoadImage
          alt={content.right.two}
          src={`/files/${content.right.one}/download?size=${
            imageContainerTwo?.current?.offsetWidth * 2
          }`}
          className="lazy-images w-full rounded lg:rounded-lg  duration-300"
          onLoad={(e) => {
            makeVisible(e);
          }}
        />
      </div>
    </div>
  );
};

export default Double;
