import React from "react";
import { Link } from "react-router-dom";

const CircleLink = ({ type, path, children, className, onClick }) => {
  return path ? (
    <Link
      to={path}
      className={`${className} bg-white block rounded-full text-center hover:bg-navLinkHover`}
    >
      {children}
    </Link>
  ) : (
    <button
      type={type ? type : ""}
      onClick={onClick ? onClick : () => {}}
      className={`${className} bg-white block rounded-full text-center hover:bg-navLinkHover`}
    >
      {children}
    </button>
  );
};

export default CircleLink;
