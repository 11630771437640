import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Login from "../pages/Login";

export const ProjectsContext = React.createContext();

export const ProjectsProvider = ({ children }) => {
  const navigate = useNavigate();
  const [loggedIn, setLoggedIn] = useState(localStorage.getItem("token"));
  const [projects, setProjects] = useState([]);
  const [publishedProjects, setPublishedProjects] = useState([]);
  const [currentMode, setCurrentMode] = useState("normal");
  const [pageSpecificClasses, setPageSpecificClasses] = useState("");

  const getOrder = (type) => {
    return (
      projects.filter((project) => project[type].value === true).length + 1
    );
  };

  const logOut = () => {
    localStorage.removeItem("token");
    setLoggedIn(false);
    navigate("/login");
  };

  useEffect(() => {
    axios
      .get("/api/projects")
      .then((res) => {
        setProjects(res.data);
        setPublishedProjects(res.data.filter((project) => project.published));
      })
      .catch((err) => console.log(err));
  }, []);

  if (!projects) return <div>FETCHING PROJECTS</div>;

  return (
    <ProjectsContext.Provider
      value={{
        logOut,
        loggedIn,
        setLoggedIn,
        projects,
        setProjects,
        publishedProjects,
        setPublishedProjects,
        currentMode,
        setCurrentMode,
        getOrder,
        pageSpecificClasses,
        setPageSpecificClasses,
      }}
    >
      {children}
    </ProjectsContext.Provider>
  );
};

export const useProjects = () => {
  const context = useContext(ProjectsContext);

  if (context === undefined) {
    throw new Error("useProjects must be used within a ProjectsProvider");
  }

  return context;
};
