import React from "react";

const Intro = ({ content, tags }) => {
  return (
    <>
      <div className="mb-18 text-grey3 text-l6 lg:mb-0 lg:col-span-3 lg:mt-2">
        {tags.map((tag) => {
          return <div key={tag}>{tag}</div>;
        })}
      </div>
      <div className="text-s4 mb-18 md:mb-40 md:text-l4 lg:col-span-6 lg:col-start-4">
        {content.one}
      </div>
    </>
  );
};

export default Intro;
