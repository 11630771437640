import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useProjects } from "../../../context/ProjectsContext";
import { spacingValue } from "../../../utils/navbarSpacer";
import NavLink from "../NavLink";
import Logo from "../../Logo";
import ModeWrapper from "../../modeButtons/ModeWrapper";

const NavBar = ({ isMobile }) => {
  const { loggedIn, setLoggedIn, logOut } = useProjects();
  const navigate = useNavigate();
  let prevScrollpos = window.pageYOffset;
  const navbar = useRef();

  const [spacing, setSpacing] = useState();
  const recalculateSpacing = () => {
    setSpacing(spacingValue());
  };

  useEffect(() => {
    recalculateSpacing();
  }, [spacing]);

  const appearOrDisappear = () => {
    if (!navbar.current) {
      return;
    } else {
      var currentScrollPos = window.pageYOffset;
      if (prevScrollpos > currentScrollPos) {
        navbar.current.style.top = "0";
        navbar.current.style.position = "fixed";
      } else {
        navbar.current.style.top = `-${navbar.current.clientHeight}px`;
        navbar.current.style.position = "sticky";
      }
      prevScrollpos = currentScrollPos;
    }
  };

  window.addEventListener("resize", recalculateSpacing);
  if (!!isMobile && navbar?.current) {
    window.addEventListener("scroll", appearOrDisappear);
  }

  return (
    <div
      ref={isMobile ? navbar : null}
      className="flex flex-col bg-warmGrey nav-background fixed w-full md:w-[calc(100%-48px)] lg:w-[calc(100%-80px)] xl:w-[calc(100%-96px)] z-10 justify-between top-0 pt-4 pb-3 xs:pt-5 pointer-events-none md:bg-transparent md:flex-row md:pt-8 lg:pt-10 duration-300"
    >
      <div className="flex order-2 md:order-1 pointer-events-auto">
        <Link
          style={isMobile ? { marginRight: spacing } : {}}
          to="/"
          className="cursor-pointer hidden md:block md:mr-4 2xl:mr-5"
        >
          <Logo />
        </Link>
        <NavLink
          style={isMobile ? { marginRight: spacing } : {}}
          className="mobilenav md:mr-4 2xl:mr-5"
          path="/projects"
        >
          Projects
        </NavLink>
        <NavLink
          style={isMobile ? { marginRight: spacing } : {}}
          className="mobilenav md:mr-4 2xl:mr-5"
          path="/images"
        >
          Images
        </NavLink>
        <NavLink
          style={isMobile ? { marginRight: spacing } : {}}
          className="mobilenav"
          path="/outcomes"
        >
          Outcomes
        </NavLink>
      </div>
      {loggedIn && (
        <div
          className="absolute right-10 md:relative pointer-events-auto order-2 text-s6 w-fit text-navText bg-navLink rounded-full cursor-pointer py-3 px-3.5 sm:px-5 md:text-l6 lg:py-15px lg:px-5 2xl:px-30px 2xl:text-l4 md:hover:bg-navLinkHover duration-300"
          onClick={() => logOut()}
        >
          LOG OUT
        </div>
      )}
      <div
        style={isMobile ? { marginBottom: spacing } : {}}
        className="flex order-1 md:order-2 pointer-events-auto"
      >
        <Link
          style={isMobile ? { marginRight: spacing } : {}}
          to="/"
          className="cursor-pointer md:hidden"
        >
          <Logo />
        </Link>
        <NavLink
          style={isMobile ? { marginRight: spacing } : {}}
          className="md:mr-4 2xl:mr-5"
          path="/about"
        >
          About
        </NavLink>
        <NavLink
          style={isMobile ? { marginRight: spacing } : {}}
          className="md:mr-4 2xl:mr-5"
          path="/notes"
        >
          Notes
        </NavLink>
        {!isMobile && <ModeWrapper />}
      </div>
    </div>
  );
};

export default NavBar;
