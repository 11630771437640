export const spacingValue = () => {
  let mobileNavs = document.querySelectorAll(".mobilenav"),
    i;
  let mobileNavsWidth = 0;
  for (i = 0; i < mobileNavs.length; ++i) {
    mobileNavsWidth = mobileNavsWidth + mobileNavs[i].offsetWidth;
  }

  let fullNavBar = document.querySelector(".available-width");
  let numberOfSpaces = 2; // There are 3 divs on the bottom line
  let spacing = (fullNavBar.clientWidth - mobileNavsWidth) / numberOfSpaces;

  if (spacing > 20) {
    spacing = 20;
  }
  return spacing;
};
