import React, { useRef } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Image = ({ content }) => {
  const imageContainer = useRef();

  const makeVisible = (e) => {
    const { currentTarget } = e;
    removeScreenHeight();
    setTimeout(() => {
      currentTarget.classList.remove("lazy-images");
    }, 300);
  };

  const removeScreenHeight = () => {
    imageContainer.current.classList.remove("h-screen");
  };

  return (
    <div
      className="mb-18 md:mb-34 w-full lg:col-span-10 lg:col-start-2 relative h-screen"
      ref={imageContainer}
    >
      <div className="placeholder bg-imgPlaceholder rounded lg:rounded-lg">
        <LazyLoadImage
          alt={content.two}
          src={`/files/${content.one}/download?size=${
            imageContainer?.current?.offsetWidth * 2
          }`}
          className="lazy-images w-full rounded lg:rounded-lg duration-300"
          onLoad={(e) => {
            makeVisible(e);
          }}
        />
      </div>
    </div>
  );
};

export default Image;
