import React from "react";

const Quote = ({ content }) => {
  return (
    <div className="mb-18 md:mb-34 lg:col-span-8 lg:col-start-4">
      <div className="mb-6 text-s3 w-full md:text-l2">{content.one}</div>
      <div className="text-s7 w-full md:text-l5">{content.two}</div>
    </div>
  );
};

export default Quote;
