import React from "react";

const ContactLink = ({ path, children }) => {
  return (
    <a
      href={path}
      className="text-s6 pb-2 mr-4 border-b border-coral lg:text-s6"
    >
      {children}
    </a>
  );
};

export default ContactLink;
