import React, { useState, useMemo, useEffect } from "react";
import axios from "axios";
import { useProjects } from "../../context/ProjectsContext";
import { useNavigate } from "react-router-dom";
import useToken from "../../hooks/useToken";
import CircleLink from "../../components/CircleLink";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  rectSortingStrategy,
} from "@dnd-kit/sortable";

import SortableItem from "../../components/SortableItem";

const Images = () => {
  const { publishedProjects } = useProjects();
  const navigate = useNavigate();
  const { token } = useToken();
  const [items, setItems] = useState([]);

  useEffect(() => {
    let imageItems = publishedProjects
      .map(({ _id, images }) =>
        images.map((image) => ({
          _id,
          image,
        }))
      )
      .flat()
      .sort((a, b) => {
        return a.image.order - b.image.order;
      });
    if (!imageItems.length) {
      return;
    } else {
      setItems(imageItems);
    }
  }, [publishedProjects]);

  const itemIds = useMemo(() => items.map((item) => item.image._id), [items]);
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      setItems((items) => {
        const oldIndex = items.findIndex(
          (item) => item.image._id === active.id
        );
        const newIndex = items.findIndex((item) => item.image._id === over.id);

        arrayMove(items, oldIndex, newIndex).map((item, index) => {
          const project = publishedProjects.find(
            (proj) => proj._id === item._id
          );
          const image = project.images.find(
            (out) => out._id === item.image._id
          );

          image.order = index + 1;
          return axios
            .put(`/api/projects/${item._id}`, project, {
              headers: {
                Authorization: `Token ${localStorage
                  .getItem("token")
                  .replace(/\"/g, "")}`,
              },
            })
            .catch((error) => navigate("/login"));
        });

        return arrayMove(items, oldIndex, newIndex);
      });
    }
  };

  const imageItem = (image) => {
    return (
      <img
        src={`/files/${image.image.value}/download`}
        className={`rounded absolute top-0 -translate-x-1/2 left-1/2 duration-200 orientation-${image.image.orientation}`}
        alt={image.image.alt}
      />
    );
  };

  if (!itemIds.length)
    return (
      <div className="flex items-center">
        <CircleLink
          path="/admin/reorder"
          className="w-14 h-14 leading-14 text-s3"
        >
          <i className="fa-solid fa-arrow-left"></i>
        </CircleLink>
        <div className="ml-10">NO IMAGES YET</div>
      </div>
    );
  return (
    <div className="px-4 pb-8 xs:px-5 md:px-6 lg:px-10 xl:px-12 min-h-screen md:col-span-9 bg-warm-grey">
      <CircleLink
        path="/admin/reorder"
        className="w-14 h-14 leading-14 text-s3"
      >
        <i className="fa-solid fa-arrow-left"></i>
      </CircleLink>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <div className="lg:grid lg:grid-cols-3 lg:gap-8 xl:gap-10 max-w-5xl m-auto">
          <SortableContext items={itemIds} strategy={rectSortingStrategy}>
            {items.map((item, index) => (
              <div className="relative" key={index}>
                <SortableItem
                  key={item.image._id}
                  id={item.image._id}
                  item={imageItem(item)}
                  className="group aspect-square p-8 group-hover:text-blue-600 lg:text-l3 bg-white"
                />
              </div>
            ))}
          </SortableContext>
        </div>
      </DndContext>
    </div>
  );
};

export default Images;
