import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";

import "swiper/css";

const OutcomeSwiper = ({ items }) => {
  return (
    <Swiper
      className="!-mx-4 xs:!-mx-5 !px-4 xs:!px-5 slider-one"
      slidesPerView={1.1}
      loop={true}
      spaceBetween={16}
    >
      {items?.map((outcome, index) => {
        return (
          <SwiperSlide key={index}>
            <Link to={`/projects/${outcome.slug}`}>
              <div className="group aspect-square relative">
                <div className="text-s5 mb-11 text-grey3">{outcome.title}</div>
                <div className="text-s2">{outcome.outcome.value}</div>
                <div className="text-s6 flex items-center mt-4">
                  {outcome.outcome.readMore}
                  <i className="fa-solid fa-arrow-right ml-2"></i>
                </div>
              </div>
            </Link>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

export default OutcomeSwiper;
