import React from "react";
import { Link } from "react-router-dom";

const NavLink = ({ path, style, className, children, onClick }) => {
  return (
    <Link
      to={path}
      style={style}
      onClick={onClick}
      className={`text-s6 w-fit text-navText bg-navLink rounded-full cursor-pointer py-3 px-3.5 sm:px-5 md:text-l6 lg:py-15px lg:px-5 2xl:px-30px 2xl:text-l4 md:hover:bg-navLinkHover duration-300 ${className}`}
    >
      {children}
    </Link>
  );
};

export default NavLink;
