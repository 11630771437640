import React, { useState, useEffect } from "react";
import axios from "axios";
import { projectCategories } from "../utils/projects";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useProjects } from "../context/ProjectsContext";
import CircleLink from "../components/CircleLink";
import CoverImage from "../components/projects/edit/CoverImage";
import ProjectImage from "../components/projects/edit/ProjectImage";
import Outcome from "../components/projects/edit/Outcome";
import Checkbox from "../components/Checkbox";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import useToken from "../hooks/useToken";

const ProjectEditor = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { token } = useToken();
  const { register, handleSubmit, reset, watch } = useForm();
  const { projects, setProjects, getOrder, setPageSpecificClasses } =
    useProjects();
  const [coverImage, setCoverImage] = useState("");
  const selectedProject = projects.find((project) => project._id === id);
  const isNewProject = !selectedProject;
  const [content, setContent] = useState([]);
  const [projectImages, setProjectImages] = useState([]);
  const [outcomes, setOutcomes] = useState([]);
  const [error, setError] = useState("");
  const published = watch("published");

  useEffect(() => {
    if (selectedProject) {
      setContent(selectedProject.content);
      setCoverImage(selectedProject.coverImage);
      setProjectImages(selectedProject.images);
      setOutcomes(selectedProject.outcomes);
      reset({
        title: selectedProject.title,
        tagline: selectedProject.tagline,
        tags: selectedProject.tags.join(", "),
        publishedOnProjectsImage:
          selectedProject.publishedOnProjectsImage.value,
        publishedOnProjectsText: selectedProject.publishedOnProjectsText.value,
        published: selectedProject.published,
      });
    }
  }, [selectedProject, reset]);

  useEffect(() => {
    setPageSpecificClasses("admin-page");

    return function cleanup() {
      setPageSpecificClasses("");
    };
  }, [setPageSpecificClasses]);

  const createProject = (data) => {
    axios
      .post("/api/projects", changedProject(data), {
        headers: {
          Authorization: `Token ${localStorage
            .getItem("token")
            .replace(/\"/g, "")}`,
        },
      })
      .then((res) => {
        let modifiedProjects = [...projects, res.data];
        setProjects(modifiedProjects);
        navigate(`/admin/${res.data._id}/content`);
      })
      .catch((error) => {
        if (error.response.data === 403) {
          navigate("/login");
        } else {
          setError(error.response.data);
        }
      });
  };

  const changedProject = (data) => {
    return {
      title: data.title,
      tagline: data.tagline,
      tags: data.tags.split(",").map((tag) => tag.trim()),
      publishedOnProjectsImage: {
        value:
          published && data.publishedOnProjectsImage !== undefined
            ? data.publishedOnProjectsImage
            : false,
        order: data.publishedOnProjectsImage
          ? isNewProject
            ? getOrder("publishedOnProjectsImage")
            : selectedProject.publishedOnProjectsImage.order
          : "0",
      },
      publishedOnProjectsText: {
        value:
          published && data.publishedOnProjectsText !== undefined
            ? data.publishedOnProjectsText
            : false,
        order: data.publishedOnProjectsText
          ? isNewProject
            ? getOrder("publishedOnProjectsText")
            : selectedProject.publishedOnProjectsText.order
          : "0",
      },
      coverImage: coverImage,
      outcomes: outcomes,
      images: projectImages,
      content: content,
      published: published,
    };
  };

  const updateProject = (data) => {
    axios
      .put(`/api/projects/${id}`, changedProject(data), {
        headers: {
          Authorization: `Token ${localStorage
            .getItem("token")
            .replace(/\"/g, "")}`,
        },
      })
      .then((res) => {
        let modifiedProjects = [...projects];
        let updatedProject = modifiedProjects.findIndex(
          (project) => project._id === id
        );
        modifiedProjects[updatedProject] = res.data;
        setProjects(modifiedProjects);
        navigate(`/admin/${res.data._id}/content`);
      })
      .catch((error) => {
        if (error.response.data === 403) {
          navigate("/login");
        } else {
          setError(error.response.data);
        }
      });
  };

  const appendOutcome = () => {
    const newOutcomeBlock = {
      order: 9999,
      value: "",
    };
    setOutcomes([...outcomes, newOutcomeBlock]);
  };

  const appendImage = () => {
    const newImageBlock = {
      order: 9999,
      alt: "",
      value: "",
      orientation: "",
    };
    setProjectImages([...projectImages, newImageBlock]);
  };

  const appendElement = (category) => {
    if (category === "image") {
      appendImage();
    } else if (category === "outcome") {
      appendOutcome();
    }
  };

  const removeOutcome = (index) => {
    const outcomeArray = [...outcomes];
    outcomeArray.splice(index, 1);
    setOutcomes(outcomeArray);
  };

  const onSubmit = (data) => {
    setError("");
    if (isNewProject) {
      createProject(data);
    } else {
      updateProject(data);
    }
  };

  const selectStyle = {
    control: (base) => ({
      ...base,
      border: 0,
      boxShadow: "none",
      marginBottom: "20px",
    }),
  };

  return (
    <div className="min-h-screen grid text-s6 pb-40 px-4 gap-4 xs:px-5 xs:gap-5 md:text-l6 md:px-6 md:grid-cols-12 lg:px-10 lg:gap-8 xl:px-12 xl:gap-10">
      <CircleLink path="/admin" className="w-14 h-14 leading-14 text-s3">
        <i className="fa-solid fa-arrow-left"></i>
      </CircleLink>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="col-span-8 col-start-3 relative"
      >
        <div className="w-full mt-6 text-s4 m-auto md:text-l4 items-center">
          <div className="mb-5">PROJECT DETAILS</div>
          <div className="mb-4 py-5 px-2 bg-white rounded">
            <div className="text-s5 md:text-l6">TITLE</div>
            <textarea
              {...register("title")}
              className="px-3 py-2 my-2 bg-warmGrey text-s5 w-full md:text-l5 rounded"
              placeholder="Name of the project"
            />
          </div>
          <div className="mb-4 py-5 px-2 bg-white rounded">
            <div className="text-s5 md:text-l6">TAGLINE</div>
            <textarea
              {...register("tagline")}
              className="px-3 py-2 my-2 bg-warmGrey text-s5 w-full md:text-l5 rounded"
              placeholder="Catchy tagline"
            />
          </div>
          <div className="mb-4 py-5 px-2 bg-white rounded">
            <div className="text-s5 md:text-l6">TAGS (comma-separated)</div>
            <textarea
              {...register("tags")}
              className="px-3 py-2 my-2 bg-warmGrey text-s5 w-full md:text-l5 rounded"
              placeholder="Content tagging, divide by commas"
            />
          </div>
          <CoverImage coverImage={coverImage} setCoverImage={setCoverImage} />
          <div className="relative grid grid-cols-2 gap-4 xs:gap-5 lg:gap-8 xl:gap-10">
            {projectImages?.map((image, index) => {
              return (
                <ProjectImage
                  key={index}
                  images={projectImages}
                  setImages={setProjectImages}
                  index={index}
                  projectImages={projectImages}
                  setProjectImages={setProjectImages}
                />
              );
            })}
          </div>

          {outcomes?.map((image, index) => {
            return (
              <div className="relative" key={index}>
                <Outcome
                  outcomes={outcomes}
                  setOutcomes={setOutcomes}
                  index={index}
                />
                <CircleLink
                  onClick={() => removeOutcome(index)}
                  className="w-10 h-10 leading-10 text-s5 absolute top-0 -left-12 text-red-600 hover:text-white"
                >
                  <i className="fa-solid fa-xmark"></i>
                </CircleLink>
              </div>
            );
          })}

          <div className="mr-6 w-1/2">
            <Select
              styles={selectStyle}
              options={projectCategories}
              onChange={(option) => appendElement(option.value)}
              placeholder="Add outcome or image"
            />
          </div>
          <div className="mb-4 p-5 bg-white rounded">
            <Checkbox ref={register} name="published" content="Published" />
            <Checkbox
              ref={register}
              name="publishedOnProjectsImage"
              content="Published on Projects page with image"
              className={`ml-10 text-l5`}
              disabled={!published}
            />
            <Checkbox
              ref={register}
              name="publishedOnProjectsText"
              content="Published on Projects page with text only"
              className={`ml-10 text-l5`}
              disabled={!published}
            />
          </div>
        </div>
        {error && (
          <div className="text-l4 absolute left-0 mt-5 text-red-600">
            {error}
          </div>
        )}
        <CircleLink
          type="submit"
          className="w-14 h-14 leading-14 text-s3 absolute right-0 mt-5"
        >
          <i className="fa-solid fa-floppy-disk"></i>
        </CircleLink>
      </form>
    </div>
  );
};

export default ProjectEditor;
