import React from "react";
import { RichTextEditor } from "@mantine/rte";

const Paragraph = ({ contentArray, setContentArray, index }) => {
  const handleTitleChange = (e, type) => {
    let modifiedContent = [...contentArray];
    modifiedContent[index].value[type] = e.currentTarget.value;
    setContentArray(modifiedContent);
  };

  const handleBodyChange = (text, type) => {
    let modifiedContent = [...contentArray];
    modifiedContent[index].value[type] = text;
    setContentArray(modifiedContent);
  };

  return (
    <div className="mb-5 py-5 px-2 bg-white rounded paragraph">
      <div className="mb-3 text-s5 md:text-l6">PARAGRAPH</div>
      <input
        className="mb-2 bg-warmGrey text-s4 w-full md:text-l3 rounded"
        placeholder="Paragraph title"
        value={contentArray[index].value?.one}
        onChange={(e) => handleTitleChange(e, "one")}
      />
      <RichTextEditor
        className="bg-warmGrey text-s6 w-full md:text-l5 rounded"
        placeholder="Paragraph content"
        value={contentArray[index].value?.two}
        onChange={(text) => handleBodyChange(text, "two")}
        controls={[["link"]]}
      />
    </div>
  );
};

export default Paragraph;
