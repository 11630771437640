export const selectedClients = [
  { name: "Projects by IF", url: "https://www.google.com" },
  { name: "Farfetch", url: "https://www.google.com" },
  { name: "Library of Things", url: "https://www.google.com" },
  { name: "Ustwo", url: "https://www.google.com" },
  { name: "Designit", url: "https://www.google.com" },
  { name: "R/GA", url: "https://www.google.com" },
  { name: "Bulb", url: "https://www.google.com" },
  { name: "Takram", url: "https://www.google.com" },
  { name: "Panasonic", url: "https://www.google.com" },
  { name: "Google", url: "https://www.google.com" },
  { name: "Meta / TTC Labs", url: "https://www.google.com" },
];

export const contentCategories = [
  { value: "intro", label: "Intro" },
  { value: "paragraph", label: "Paragraph" },
  { value: "sentence", label: "Sentence" },
  { value: "quote", label: "Quote" },
  { value: "image", label: "Image" },
  { value: "double", label: "Double Image" },
  { value: "video", label: "Video" },
];

export const projectCategories = [
  { value: "image", label: "Image" },
  { value: "outcome", label: "Outcome" },
];

export const reorderTypes = [
  { tag: "projects-with-image", name: "Reorder projects with image" },
  { tag: "projects-with-text", name: "Reorder projects with text" },
  { tag: "images", name: "Reorder images page" },
  { tag: "outcomes", name: "Reorder outcomes page" },
];
