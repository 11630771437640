import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { useProjects } from "../../context/ProjectsContext";
import { Link } from "react-router-dom";
import CircleLink from "../CircleLink";
import info from "../../images/info.svg";

import "swiper/css";

const ImageSwiper = ({ items }) => {
  const { currentMode } = useProjects();
  const [showAlt, setShowAlt] = useState(false);
  return (
    <Swiper
      slidesPerView={1}
      loop={true}
      className="slider-one !-mx-4 !-xs:mx-5"
      onTransitionStart={() => setShowAlt(false)}
    >
      {items?.map((image, index) => {
        return (
          <SwiperSlide key={index}>
            <div className="group aspect-square max-h-50vh m-auto relative max-w-[calc(100%-32px)] sm:max-w-[calc(100%-40px)] h-96">
              {showAlt ? (
                <div
                  onClick={() => setShowAlt(false)}
                  className={`text-grey1 flex flex-col justify-end p-4 text-s7 bg-cardBG rounded absolute top-0 -translate-x-1/2 left-1/2 md:text-l7 orientation-${image.image.orientation} -translate-y-1/2 swiper-image`}
                >
                  <CircleLink
                    className={`${
                      currentMode === "sunset-mode" ? "bg-white" : "bg-grey5"
                    } w-10 h-10 leading-10 text-s5 absolute top-4 right-4 text-grey2`}
                  >
                    <i className="fa-solid fa-xmark"></i>
                  </CircleLink>
                  <Link to={`/projects/${image.slug}`}>
                    <div className="pb-4">{image.image.alt}</div>
                    <div>
                      View project
                      <i className="fa-solid fa-arrow-right inline ml-1 duration-300 delay-200"></i>
                    </div>
                  </Link>
                </div>
              ) : (
                <div
                  className={`text-grey1 flex flex-col justify-end text-s7 bg-cardBG rounded absolute top-0 -translate-x-1/2 left-1/2 md:text-l7 orientation-${image.image.orientation} -translate-y-1/2 swiper-image`}
                  onClick={() => setShowAlt(true)}
                >
                  <img
                    src={info}
                    className="bg-grey2 opacity-80 w-10 h-10 p-3 absolute top-4 right-4 rounded-full"
                    alt="more info"
                  />

                  <img
                    src={`/files/${image.image.value}/download?size=${
                      window.innerWidth * 2
                    }`}
                    className={`w-full h-full rounded`}
                    alt={image.image.alt}
                  />
                </div>
              )}
            </div>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

export default ImageSwiper;
