import React from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Image = ({
  contentArray,
  setContentArray,
  index,
  space = null,
  isDouble = false,
  className,
}) => {
  const navigate = useNavigate();
  const handleUpload = (event, type) => {
    var formData = new FormData();
    formData.append("file", event.target.files[0]);
    axios
      .post("/files", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Token ${localStorage
            .getItem("token")
            .replace(/\"/g, "")}`,
        },
      })
      .then((res) => {
        if (space) {
          let modifiedContent = [...contentArray];
          modifiedContent[index].value[space] = { [type]: res.data._id };
          setContentArray(modifiedContent);
        } else {
          let modifiedContent = [...contentArray];
          modifiedContent[index].value[type] = res.data._id;
          setContentArray(modifiedContent);
        }
      })
      .catch((error) => navigate("/login"));
  };

  const handleChange = (e, type) => {
    let modifiedContent = [...contentArray];
    if (space) {
      modifiedContent[index].value[space][type] = e.target.value;
    } else {
      modifiedContent[index].value[type] = e.target.value;
    }
    setContentArray(modifiedContent);
  };

  const shouldShowImageUploader = (index) => {
    return (
      (!isDouble && contentArray[index].value?.one === "") ||
      (isDouble &&
        (!contentArray[index].value?.left || !contentArray[index].value?.right))
    );
  };

  return (
    <div className={`mb-5 py-5 px-2 bg-white rounded ${className}`}>
      {!isDouble && contentArray[index].value?.one && (
        <>
          <img
            src={`/files/${contentArray[index].value?.one}/download`}
            className="w-full"
            alt={contentArray[index].value?.two}
          />
          <textarea
            placeholder="alt text"
            value={contentArray[index].value?.two}
            onChange={(e) => handleChange(e, "two")}
            className="bg-warmGrey text-s5 w-full md:text-l4 mt-3 rounded"
          />
        </>
      )}

      {isDouble && contentArray[index].value[space]?.one && (
        <div>
          <img
            src={`/files/${contentArray[index].value[space].one}/download`}
            className="w-full"
            alt={contentArray[index].value[space].two}
          />
          <textarea
            placeholder="alt text"
            value={contentArray[index].value[space].two}
            onChange={(e) => handleChange(e, "two")}
            className="bg-warmGrey text-s5 w-full md:text-l4 mt-3 rounded"
          />
        </div>
      )}

      {shouldShowImageUploader(index) && (
        <input
          type="file"
          accept="image/*"
          name="file"
          onChange={(e) => handleUpload(e, "one")}
        />
      )}
    </div>
  );
};

export default Image;
