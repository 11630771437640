import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

export function SortableItem(props) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: props.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    cursor: "move",
    transition,
  };

  return (
    <>
      {props.handle ? (
        <div ref={setNodeRef} style={style} className={props.className}>
          <div {...attributes} {...listeners}>
            {props.handle}
          </div>
          {props.item}
        </div>
      ) : (
        <div
          ref={setNodeRef}
          style={style}
          className={props.className}
          {...attributes}
          {...listeners}
        >
          {props.item}
        </div>
      )}
    </>
  );
}

export default SortableItem;
