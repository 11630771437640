import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
import { useProjects } from "../context/ProjectsContext";
import Title from "../components/projects/Title";
import Intro from "../components/projects/Intro";
import Paragraph from "../components/projects/Paragraph";
import Sentence from "../components/projects/Sentence";
import Image from "../components/projects/Image";
import DoubleImage from "../components/projects/Double";
import Video from "../components/projects/Video";
import Quote from "../components/projects/Quote";
import ContactBar from "../components/ContactBar";

const ProjectDetails = () => {
  const { projects, setPageSpecificClasses } = useProjects();
  const { id } = useParams();
  const selectedProject = projects.find((project) => project.slug === id);
  const content = selectedProject?.content;
  useEffect(() => {
    setPageSpecificClasses("white-page");

    return function cleanup() {
      setPageSpecificClasses("");
    };
  }, [setPageSpecificClasses]);
  if (!selectedProject) return <div>Wait</div>;
  return (
    <>
      <Helmet>
        <title>{selectedProject.title}</title>
      </Helmet>
      <div className="lg:grid md:grid-cols-12 gap-4 lg:gap-8 xl:gap-10">
        <Title project={selectedProject} />
      </div>
      {content?.map((detail, index) => {
        return (
          <div
            key={detail._id}
            className="lg:grid md:grid-cols-12 gap-4 lg:gap-8 xl:gap-10"
          >
            {detail.category === "intro" && (
              <Intro content={detail.value} tags={selectedProject.tags} />
            )}
            {detail.category === "image" && <Image content={detail.value} />}
            {detail.category === "double" && (
              <DoubleImage content={detail.value} />
            )}
            {detail.category === "video" && <Video content={detail.value} />}
            {detail.category === "sentence" && (
              <Sentence content={detail.value} />
            )}
            {detail.category === "paragraph" && (
              <Paragraph
                content={detail.value}
                contentArray={content}
                index={index}
              />
            )}
            {detail.category === "quote" && <Quote content={detail.value} />}
          </div>
        );
      })}
      <ContactBar />
    </>
  );
};

export default ProjectDetails;
