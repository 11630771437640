import React, { useState, useEffect } from "react";
import CircleLink from "../CircleLink";
import axios from "axios";

const WarningPopup = ({ projects, setProjects, project, closeWarning }) => {
  const deleteProject = (id) => {
    axios
      .delete(`/api/projects/${id}`, {
        headers: {
          Authorization: `Token ${localStorage
            .getItem("token")
            .replace(/\"/g, "")}`,
        },
      })
      .then((res) => {
        let modifiedProjects = [...projects];
        let deletedProject = modifiedProjects.findIndex(
          (project) => project._id === id
        );
        modifiedProjects.splice(deletedProject, 1);
        setProjects(modifiedProjects);
        closeWarning();
      });
  };

  return (
    <div className="fixed w-[50vw] h-[50vh] inset-1/2 -translate-y-1/2 -translate-x-1/2 bg-coral flex flex-col justify-center items-center text-center">
      <div className="w-3/4 text-l3 mb-3">Warning!</div>
      <div className="w-3/4 text-l4 mb-2">
        You are about to remove project {project.title}.
      </div>
      <div className="w-3/4 text-l4">Are you sure?</div>
      <CircleLink
        onClick={() => deleteProject(project._id)}
        className="w-10 h-10 leading-10 text-s5 bottom-5 right-5 absolute"
      >
        <i className="fa-solid fa-trash"></i>
      </CircleLink>
      <CircleLink
        onClick={() => closeWarning(project._id)}
        className="w-10 h-10 leading-10 text-s5 top-5 right-5 absolute"
      >
        <i className="fa-solid fa-close"></i>
      </CircleLink>
    </div>
  );
};

export default WarningPopup;
