import React from "react";
import { useProjects } from "../../../context/ProjectsContext";
import Sun from "../Sun";
import Moon from "../Moon";
import SunSet from "../SunSet";

const ModeWrapper = ({ className }) => {
  const { currentMode, setCurrentMode } = useProjects();

  const toggleMode = () => {
    if (currentMode === "normal") {
      setCurrentMode("sunset-mode");
    } else if (currentMode === "sunset-mode") {
      setCurrentMode("dark-mode");
    } else {
      setCurrentMode("normal");
    }
  };

  return (
    <div className={className} onClick={() => toggleMode()}>
      {currentMode === "normal" && <SunSet />}
      {currentMode === "sunset-mode" && <Moon />}
      {currentMode === "dark-mode" && <Sun />}
    </div>
  );
};

export default ModeWrapper;
