import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import { useProjects } from "../../context/ProjectsContext";
import { useNavigate } from "react-router-dom";
import useToken from "../../hooks/useToken";
import CircleLink from "../../components/CircleLink";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";

import SortableItem from "../../components/SortableItem";

const ProjectsWithImages = () => {
  const { publishedProjects } = useProjects();
  const navigate = useNavigate();
  const { token } = useToken();

  const projectsWithImage = publishedProjects
    .filter((project) => project.publishedOnProjectsImage.value)
    .sort((a, b) => {
      return (
        a.publishedOnProjectsImage.order - b.publishedOnProjectsImage.order
      );
    });

  const [items, setItems] = useState(projectsWithImage);

  const itemIds = useMemo(() => items.map((item) => item._id), [items]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      setItems((items) => {
        const oldIndex = items.findIndex((item) => item._id === active.id);
        const newIndex = items.findIndex((item) => item._id === over.id);

        arrayMove(items, oldIndex, newIndex).map((project, index) => {
          project.publishedOnProjectsImage.order = index + 1;
          return axios
            .put(`/api/projects/${project._id}`, project, {
              headers: {
                Authorization: `Token ${localStorage
                  .getItem("token")
                  .replace(/\"/g, "")}`,
              },
            })
            .catch((error) => navigate("/login"));
        });

        return arrayMove(items, oldIndex, newIndex);
      });
    }
  };
  if (!itemIds.length)
    return (
      <div className="flex items-center">
        <CircleLink
          path="/admin/reorder"
          className="w-14 h-14 leading-14 text-s3"
        >
          <i className="fa-solid fa-arrow-left"></i>
        </CircleLink>
        <div className="ml-10">NO PROJECTS YET</div>
      </div>
    );
  return (
    <div className="px-4 pb-8 xs:px-5 md:px-6 lg:px-10 xl:px-12 min-h-screen md:col-span-9 bg-warm-grey text-l2">
      <CircleLink
        path="/admin/reorder"
        className="w-14 h-14 leading-14 text-s3"
      >
        <i className="fa-solid fa-arrow-left"></i>
      </CircleLink>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <SortableContext items={itemIds} strategy={verticalListSortingStrategy}>
          {items.map((item) => (
            <SortableItem
              key={item._id}
              id={item._id}
              item={item.title}
              className="my-4"
            />
          ))}
        </SortableContext>
      </DndContext>
    </div>
  );
};

export default ProjectsWithImages;
