import React from "react";
import "./SunSet.scss";

const SunSet = () => {
  return (
    <div id="mode-button-sunset">
      <svg
        width="60px"
        height="60px"
        viewBox="0 0 60 60"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <linearGradient id="lgrad" x1="50%" y1="0%" x2="50%" y2="100%">
            <stop
              offset="0%"
              style={{ stopColor: "rgb(172,192,251)", stopOpacity: "1.00" }}
            />
            <stop
              offset="30%"
              style={{ stopColor: "rgb(255,187,194)", stopOpacity: "1.00" }}
            />
            <stop
              offset="91%"
              style={{ stopColor: "rgb(252,217,175)", stopOpacity: "1.00" }}
            />
          </linearGradient>
        </defs>
        <g
          id="Page-1"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g id="Group">
            <circle
              id="Oval"
              fillRule="nonzero"
              cx="30"
              cy="30"
              r="30"
            ></circle>
            <g
              id="Logo-(1)"
              transform="translate(16.000000, 20.000000)"
              stroke="#333333"
              strokeLinecap="round"
              strokeWidth="2"
            >
              <path
                d="M18.6875,11.1459 C18.6875,8.5811 16.5888,6.502 14,6.502 C11.4112,6.502 9.3125,8.5811 9.3125,11.1459"
                id="Path"
                strokeLinejoin="round"
              ></path>
              <line
                x1="14"
                y1="0"
                x2="14"
                y2="1.8576"
                id="Path"
                strokeLinejoin="round"
              ></line>
              <line
                x1="6.7056"
                y1="2.9902"
                x2="8.0368"
                y2="4.3091"
                id="Path"
                strokeLinejoin="round"
              ></line>
              <line
                x1="19.9624"
                y1="4.3091"
                x2="21.2937"
                y2="2.9902"
                id="Path"
                strokeLinejoin="round"
              ></line>
              <line x1="28" y1="11.2168" x2="0" y2="11.2168" id="Path"></line>
              <line x1="16.7515" y1="16" x2="11.2515" y2="16" id="Path"></line>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
};

export default SunSet;
