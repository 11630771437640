import React from "react";
import "./Logo.scss";

const Logo = () => {
  window.onscroll = function () {
    scrollRotate();
  };

  function scrollRotate() {
    let image = document.getElementById("logo");
    if (image) {
      image.style.transform = "rotate(" + window.pageYOffset / 2 + "deg)";
    }
  }

  return (
    <div id="logo">
      <svg
        width="60"
        height="60"
        viewBox="0 0 60 60"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle className="face" cx="30" cy="30" r="30" />
        <path
          d="M31.1445 22V33.8594C31.1445 35.6406 30.418 36.9062 28.3906 36.9062C26.7734 36.9062 25.4023 36.1094 25.4023 33.0625L23 33.2969C23.0117 37.5625 25.4609 39.1914 28.4023 39.1914C31.543 39.1914 33.5352 37.3398 33.5352 34.0234V22H31.1445Z"
          fill="#333333"
        />
        <circle className="eye" cx="42" cy="24" r="2" />
        <circle className="eye" cx="21" cy="24" r="2" />
      </svg>
    </div>
  );
};

export default Logo;
