import React from "react";

const Checkbox = React.forwardRef(
  ({ disabled = false, name, content, error, className }, forwardRef) => {
    if (error) {
      console.log(error);
    }
    return (
      <div className={`flex align-center my-8 ${className}`}>
        <input
          {...forwardRef(name)}
          className={`${
            disabled ? "bg-grey4" : "bg-red-600 checked:bg-green-600"
          } form-check-input appearance-none h-8 w-8 rounded-full focus:outline-none transition duration-200 mr-2 cursor-pointer disabled:cursor-not-allowed`}
          type="checkbox"
          disabled={disabled}
          id={name}
        />
        <label
          className={`form-check-label inline-block ${
            disabled ? "text-grey4" : "text-gray-800"
          }`}
          htmlFor="name"
        >
          {content}
        </label>
      </div>
    );
  }
);

export default Checkbox;
