import React, { useState, useMemo, useEffect } from "react";
import axios from "axios";
import Select from "react-select";
import { contentCategories } from "../utils/projects";
import { useParams, useNavigate } from "react-router-dom";
import { useProjects } from "../context/ProjectsContext";
import useToken from "../hooks/useToken";
import CircleLink from "../components/CircleLink";
import Title from "../components/projects/Title";
import Sentence from "../components/projects/content/Sentence";
import Intro from "../components/projects/content/Intro";
import Image from "../components/projects/content/Image";
import Double from "../components/projects/content/Double";
import Video from "../components/projects/content/Video";
import Paragraph from "../components/projects/content/Paragraph";
import Quote from "../components/projects/content/Quote";
import {
  DndContext,
  closestCenter,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  rectSortingStrategy,
} from "@dnd-kit/sortable";

import SortableItem from "../components/SortableItem";

const ContentEditor = () => {
  const { id } = useParams();
  const { projects, setProjects, setPageSpecificClasses } = useProjects();
  const navigate = useNavigate();
  const { token } = useToken();
  const project = projects?.find((project) => project._id === id);
  const [contentArray, setContentArray] = useState();
  const [error, setError] = useState("");
  const itemIds = useMemo(() => {
    if (!contentArray) {
      return [];
    } else {
      return contentArray.map((item) => item.order.toString());
    }
  }, [contentArray]);
  const sensors = useSensors(useSensor(PointerSensor));

  useEffect(() => {
    setPageSpecificClasses("admin-page");

    return function cleanup() {
      setPageSpecificClasses("");
    };
  }, [setPageSpecificClasses]);

  useEffect(() => {
    setContentArray(project?.content);
  }, [project]);

  const updateProject = () => {
    let changedProject = { ...project };
    contentArray.map((item, index) => (item.order = index + 1));
    changedProject.content = contentArray;

    axios
      .put(`/api/projects/${id}`, changedProject, {
        headers: {
          Authorization: `Token ${localStorage
            .getItem("token")
            .replace(/\"/g, "")}`,
        },
      })
      .then((res) => {
        let modifiedProjects = [...projects];
        let updatedProject = modifiedProjects.findIndex(
          (project) => project._id === id
        );
        modifiedProjects[updatedProject] = res.data;
        setProjects(modifiedProjects);
        navigate("/admin");
      })
      .catch((error) => {
        if (error.response.data === 403) {
          navigate("/login");
        } else {
          setError(error.response.data);
        }
      });
  };

  const appendElement = (category) => {
    const newContentBlock = {
      category,
      order: contentArray.length + 1,
      value: { one: "" },
    };
    setContentArray([...contentArray, newContentBlock]);
  };

  const removeElement = (index) => {
    const elementArray = [...contentArray];
    elementArray.splice(index, 1);
    setContentArray(elementArray);
  };

  const selectStyle = {
    control: (base) => ({
      ...base,
      border: 0,
      boxShadow: "none",
    }),
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      setContentArray((contentArray) => {
        const oldIndex = contentArray.findIndex(
          (item) => item.order.toString() === active.id
        );
        const newIndex = contentArray.findIndex(
          (item) => item.order.toString() === over.id
        );
        return arrayMove(contentArray, oldIndex, newIndex);
      });
    }
  };

  const handle = (
    <CircleLink
      onClick={() => {}}
      className="w-10 h-10 leading-10 text-s5 absolute top-0 -left-12 text-grey1 hover:text-white"
    >
      <i className="fa-solid fa-arrows-up-down-left-right"></i>
    </CircleLink>
  );

  if (!project) {
    return <div>Fetching projects</div>;
  }
  return (
    <div className="min-h-screen pb-40 grid text-s6 px-4 gap-4 xs:px-5 xs:gap-5 md:text-l6 md:px-6 md:grid-cols-12 lg:px-10 lg:gap-8 xl:px-12 xl:gap-10">
      <CircleLink
        path={`/admin/${id}`}
        className="w-14 h-14 leading-14 text-s3"
      >
        <i className="fa-solid fa-arrow-left"></i>
      </CircleLink>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <div className="col-span-6 col-start-4">
          <Title project={project} className="mb-5 md:mb-5" />
          <SortableContext items={itemIds} strategy={rectSortingStrategy}>
            {contentArray?.map((detail, index) => {
              return (
                <SortableItem
                  key={detail.order.toString()}
                  id={detail.order.toString()}
                  className="relative"
                  handle={handle}
                  item={
                    <>
                      {detail.category === "intro" && (
                        <Intro
                          contentArray={contentArray}
                          setContentArray={setContentArray}
                          index={index}
                        />
                      )}
                      {detail.category === "paragraph" && (
                        <Paragraph
                          contentArray={contentArray}
                          setContentArray={setContentArray}
                          index={index}
                        />
                      )}
                      {detail.category === "quote" && (
                        <Quote
                          contentArray={contentArray}
                          setContentArray={setContentArray}
                          index={index}
                        />
                      )}
                      {detail.category === "sentence" && (
                        <Sentence
                          contentArray={contentArray}
                          setContentArray={setContentArray}
                          index={index}
                        />
                      )}
                      {detail.category === "image" && (
                        <Image
                          contentArray={contentArray}
                          setContentArray={setContentArray}
                          index={index}
                        />
                      )}
                      {detail.category === "double" && (
                        <Double
                          contentArray={contentArray}
                          setContentArray={setContentArray}
                          index={index}
                        />
                      )}
                      {detail.category === "video" && (
                        <Video
                          contentArray={contentArray}
                          setContentArray={setContentArray}
                          index={index}
                        />
                      )}
                      <CircleLink
                        onClick={() => removeElement(index)}
                        className="w-10 h-10 leading-10 text-s5 absolute top-0 -right-12 text-red-600 hover:text-white"
                      >
                        <i className="fa-solid fa-xmark"></i>
                      </CircleLink>
                    </>
                  }
                ></SortableItem>
              );
            })}
          </SortableContext>
          <div
            className={
              "flex justify-between w-full mt-6 text-s4 m-auto md:text-l4 items-center"
            }
          >
            <div className="mr-6 w-1/2">
              <Select
                styles={selectStyle}
                options={contentCategories}
                onChange={(option) => appendElement(option.value)}
                placeholder="Add new section"
              />
            </div>

            {error && (
              <div className="text-l4 absolute left-0 mt-5 text-red-600">
                {error}
              </div>
            )}

            <CircleLink
              onClick={() => updateProject()}
              className="w-14 h-14 leading-14 text-s3"
            >
              <i className="fa-solid fa-floppy-disk"></i>
            </CircleLink>
          </div>
        </div>
      </DndContext>
    </div>
  );
};

export default ContentEditor;
