import React from "react";

const Outcome = ({ outcomes, setOutcomes, index }) => {
  const handleChange = (e, type) => {
    let modifiedContent = [...outcomes];
    modifiedContent[index][type] = e.target.value;
    setOutcomes(modifiedContent);
  };

  return (
    <div className="mb-5 py-5 px-2 bg-white rounded">
      <div className="mb-3 text-s5 md:text-l6">OUTCOME</div>
      <textarea
        placeholder="write an outcome"
        value={outcomes[index].value}
        onChange={(e) => handleChange(e, "value")}
        className="px-3 py-2 bg-warmGrey text-s5 w-full md:text-l5 rounded mb-2"
      />
      <input
        placeholder="invitation to read more"
        value={outcomes[index].readMore}
        onChange={(e) => handleChange(e, "readMore")}
        className="px-3 py-2 bg-warmGrey text-s6 w-full md:text-l5 rounded"
      />
    </div>
  );
};

export default Outcome;
