import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import CircleLink from "../components/CircleLink";
import { useProjects } from "../context/ProjectsContext";
import axios from "axios";

const Press = () => {
  const [press, setPress] = useState([]);
  const [error, setError] = useState("");
  const { projects } = useProjects();
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get("/press")
      .then((res) => {
        setPress(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const appendElement = () => {
    const newPress = {
      name: "",
      project: "",
      link: "",
      order: press.length + 1,
    };

    setPress([...press, newPress]);
  };

  const removeElement = (index, id) => {
    if (id) {
      axios
        .delete(`/press/${id}`, {
          headers: {
            Authorization: `Token ${localStorage
              .getItem("token")
              .replace(/\"/g, "")}`,
          },
        })
        .then((res) => {
          const elementArray = [...press];
          elementArray.splice(index, 1);
          setPress(elementArray);
        });
    } else {
      const elementArray = [...press];
      elementArray.splice(index, 1);
      setPress(elementArray);
    }
  };

  const handleChange = (value, index, type) => {
    setError("");
    let modifiedContent = [...press];
    modifiedContent[index][type] = value;
    setPress(modifiedContent);
  };

  const addLink = (
    <CircleLink
      type="button"
      onClick={() => appendElement()}
      className="fixed bottom-10 right-10 w-14 h-14 leading-14 text-s3"
    >
      <i className="fa-solid fa-plus"></i>
    </CircleLink>
  );

  const submitForm = () => {
    press.forEach((item) => {
      axios
        .put("/press", item, {
          headers: {
            Authorization: `Token ${localStorage
              .getItem("token")
              .replace(/\"/g, "")}`,
          },
        })
        .then((res) => {
          setPress(...press);
          navigate("/about");
        })
        .catch((error) => {
          if (error.response.data === 403) {
            navigate("/login");
          } else {
            setError(error.response.data);
          }
        });
    });
  };

  const selectStyle = {
    control: (base) => ({
      ...base,
      border: 0,
      boxShadow: "none",
    }),
  };

  if (!projects.length) return <div>No press yet {addLink}</div>;
  if (!press.length) return <div>No press yet {addLink}</div>;

  return (
    <div className="w-full text-center pb-40">
      <div>
        {press?.map((item, index) => {
          return (
            <div
              key={index}
              className="text-s4 md:text-l4 m-auto mb-10 max-w-lg relative"
            >
              <input
                className="mb-2 text-s5 md:text-l5 rounded p-2 w-full"
                placeholder="Article title"
                value={item.name}
                onChange={(e) => handleChange(e.target.value, index, "name")}
              />
              <input
                className="mb-2 text-s5 md:text-l5 rounded p-2 w-full"
                placeholder="Article link"
                value={item.link}
                onChange={(e) => handleChange(e.target.value, index, "link")}
              />
              <Select
                styles={selectStyle}
                options={projects.map((project) => {
                  return {
                    value: project.title,
                    label: project.title,
                  };
                })}
                value={{
                  value: projects.filter(
                    (project) => project.title === item.project
                  )[0]?.title,
                  label: projects.filter(
                    (project) => project.title === item.project
                  )[0]?.title,
                }}
                onChange={(e) => handleChange(e.value, index, "project")}
                placeholder="Add new section"
              />

              <CircleLink
                type="button"
                onClick={() => removeElement(index, item._id)}
                className="whereisit w-10 h-10 leading-10 text-s5 absolute top-0 -right-12 text-red-600 hover:text-white"
              >
                <i className="fa-solid fa-xmark"></i>
              </CircleLink>
            </div>
          );
        })}
        <CircleLink
          type="button"
          onClick={() => submitForm()}
          className="fixed w-14 h-14 leading-14 text-s3 bottom-10 right-28"
        >
          <i className="fa-solid fa-floppy-disk"></i>
        </CircleLink>
      </div>
      {addLink}
      {error && (
        <div className="text-l4 absolute left-0 mt-5 text-red-600">{error}</div>
      )}
    </div>
  );
};

export default Press;
