import React from "react";

const Quote = ({ contentArray, setContentArray, index }) => {
  const handleChange = (e, type) => {
    let modifiedContent = [...contentArray];
    modifiedContent[index].value[type] = e.target.value;
    setContentArray(modifiedContent);
  };

  return (
    <div className="mb-5 py-5 px-2 bg-white rounded">
      <div className="mb-3 text-s5 md:text-l6">QUOTE</div>
      <textarea
        className="mb-2 bg-warmGrey text-s3 w-full md:text-l2 rounded"
        placeholder="Quote content"
        value={contentArray[index].value?.one}
        onChange={(e) => handleChange(e, "one")}
      />
      <input
        className="bg-warmGrey text-s7 w-full md:text-l5 rounded"
        placeholder="Author"
        value={contentArray[index].value?.two}
        onChange={(e) => handleChange(e, "two")}
      />
    </div>
  );
};

export default Quote;
