import React, { useEffect, useState } from "react";
import axios from "axios";
import { useProjects } from "../context/ProjectsContext";
import { useNavigate } from "react-router-dom";
import useToken from "../hooks/useToken";
import CircleLink from "../components/CircleLink";
import WarningPopup from "../components/WarningPopup";
import Login from "./Login";
import { Link } from "react-router-dom";

const Admin = () => {
  const navigate = useNavigate();
  const [projectToDelete, setProjectToDelete] = useState(false);
  let { projects, setProjects, setPageSpecificClasses } = useProjects();

  const showWarning = (project) => {
    setProjectToDelete(project);
  };

  const closeWarning = () => {
    setProjectToDelete(null);
  };

  useEffect(() => {
    setPageSpecificClasses("admin-page");

    return function cleanup() {
      setPageSpecificClasses("");
    };
  }, [setPageSpecificClasses]);

  if (!projects) {
    return <div>Fetching projects</div>;
  }
  return (
    <div className="grid grid-cols-12 grid-rows-auto text-l7 min-h-screen">
      {projectToDelete && (
        <WarningPopup
          projects={projects}
          project={projectToDelete}
          setProjects={setProjects}
          closeWarning={closeWarning}
        />
      )}
      <div className="h-fit col-span-8 col-start-3 bg-white p-4 mb-10">
        <div className="grid grid-cols-8 pb-5 gap-4 items-center">
          <div className="col-span-3 pl-5"></div>
          <div className="m-auto text-center">Project with image</div>
          <div className="m-auto text-center">Project with text</div>
          <div className="m-auto text-center">Image page</div>
          <div className="m-auto text-center">Outcome page</div>
          <div className="m-auto text-center"></div>
        </div>
        {!projects.length ? (
          <div className="text-l5 pt-5">
            Hi James, here you can upload your first project. Hit the + button
            in the bottom right corner.
          </div>
        ) : (
          projects?.map((project) => {
            return (
              <div
                key={project.slug}
                className="grid grid-cols-8 py-3 gap-4 items-center bg-warmGrey mb-4"
              >
                <Link
                  to={`/projects/${project.slug}`}
                  className="col-span-3 pl-5 text-l5"
                >
                  {project.title}
                </Link>
                {project.published ? (
                  <>
                    <input
                      checked={project.publishedOnProjectsImage.value}
                      className="form-check-input appearance-none h-6 w-6 rounded-full bg-red-600 checked:bg-green-600 disabled:cursor-not-allowed m-auto"
                      type="checkbox"
                      disabled={true}
                    />
                    <input
                      checked={project.publishedOnProjectsText.value}
                      className="form-check-input appearance-none h-6 w-6 rounded-full bg-red-600 checked:bg-green-600 disabled:cursor-not-allowed m-auto"
                      type="checkbox"
                      disabled={true}
                    />
                    <input
                      checked={project.images.length}
                      className="form-check-input appearance-none h-6 w-6 rounded-full bg-red-600 checked:bg-green-600 disabled:cursor-not-allowed m-auto"
                      type="checkbox"
                      disabled={true}
                    />
                    <input
                      checked={project.outcomes.length}
                      className="form-check-input appearance-none h-6 w-6 rounded-full bg-red-600 checked:bg-green-600 disabled:cursor-not-allowed m-auto"
                      type="checkbox"
                      disabled={true}
                    />
                  </>
                ) : (
                  <div className="col-span-4">PROJECT NOT PUBLISHED</div>
                )}
                <div className="flex">
                  <CircleLink
                    path={`/admin/${project._id}`}
                    className="w-10 h-10 leading-10 text-s5 m-auto"
                  >
                    <i className="fa-solid fa-pen"></i>
                  </CircleLink>
                  <CircleLink
                    onClick={() => showWarning(project)}
                    className="w-10 h-10 leading-10 text-s5 m-auto"
                  >
                    <i className="fa-solid fa-trash"></i>
                  </CircleLink>
                </div>
              </div>
            );
          })
        )}
      </div>
      <div className="col-start-12 text-l4">
        <div className="mb-4 hover:text-yellow">
          <Link to="/admin/press">Press</Link>
        </div>
        <div className="mb-4 hover:text-yellow">
          <Link to="/admin/clients">Clients</Link>
        </div>
        <div className="mb-4 hover:text-yellow">
          <Link to="/admin/homeAndAbout">Home&About</Link>
        </div>
        <div className="mb-4 hover:text-yellow">
          <Link to="/admin/articles">Blog</Link>
        </div>
      </div>
      <CircleLink
        path="/admin/reorder"
        className="fixed bottom-10 left-10 w-14 h-14 leading-14 text-s3"
      >
        <i className="fa-solid fa-arrows-rotate"></i>
      </CircleLink>
      <CircleLink
        path="/admin/new-project"
        className="fixed bottom-10 right-10 w-14 h-14 leading-14 text-s3"
      >
        <i className="fa-solid fa-plus"></i>
      </CircleLink>
    </div>
  );
};

export default Admin;
