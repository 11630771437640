import React, { useState, useRef } from "react";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import play from "../../../images/play-button.svg";
import pause from "../../../images/pause-button.svg";

const Video = ({ content }) => {
  const [playing, setPlaying] = useState(true);
  const [device, setDevice] = useState(
    document.body.clientWidth < 768 ? "mobile" : "desktop"
  );
  const video = useRef();
  const videoContainer = useRef();
  const makeVisible = () => {
    removeScreenHeight();
    setTimeout(() => {
      video.current.classList.remove("lazy-images");
    }, 500);
  };
  const playVideo = () => {
    setPlaying(true);
    video.current.classList.add("pause-cursor");
    video.current.classList.remove("play-cursor");
    video.current.play();
  };

  const pauseVideo = () => {
    setPlaying(false);
    video.current.classList.add("play-cursor");
    video.current.classList.remove("pause-cursor");
    video.current.pause();
  };

  const removeScreenHeight = () => {
    videoContainer.current.classList.remove("h-screen");
  };

  return (
    <div
      className="placeholder bg-imgPlaceholder rounded lg:rounded-lg mb-18 md:mb-34 w-full lg:col-span-10 lg:col-start-2 relative h-screen"
      ref={videoContainer}
    >
      <LazyLoadComponent
        afterLoad={() => {
          makeVisible();
        }}
      >
        <video
          ref={video}
          className="lazy-images w-full rounded lg:rounded-lg pause-cursor duration-300"
          autoPlay
          muted
          loop
          playsInline
          onClick={playing ? () => pauseVideo() : () => playVideo()}
        >
          <source
            src={`/files/${content.one}/download?device=${device}`}
            type="video/mp4"
          />
        </video>
      </LazyLoadComponent>
      {!playing ? (
        <img
          src={play}
          alt="play button"
          className="absolute bottom-4 right-4 w-8 h-8 md:w-10 md:h-10 lg:hidden cursor-pointer"
          onClick={() => playVideo()}
        />
      ) : (
        <img
          src={pause}
          alt="pause button"
          className="absolute bottom-4 right-4 w-8 h-8 md:w-10 md:h-10 lg:hidden cursor-pointer"
          onClick={() => pauseVideo()}
        />
      )}
    </div>
  );
};

export default Video;
