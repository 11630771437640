import React, { useState, useRef, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { useProjects } from "../context/ProjectsContext";
import OutcomeSwiper from "../components/OutcomeSwiper";

const Outcomes = () => {
  const { publishedProjects } = useProjects();
  const square = useRef();
  const [x, setX] = useState();
  const [y, setY] = useState();
  const [imageWidth, setImageWidth] = useState(0);
  const [isMobile, setIsMobile] = useState(document.body.clientWidth < 768);
  const [outcomeArray, setOutcomeArray] = useState([]);

  const checkIfMobile = () => {
    setIsMobile(document.body.clientWidth < 768);
  };

  window.addEventListener("resize", checkIfMobile);

  useEffect(() => {
    const outcomeItems = publishedProjects
      .map(({ slug, title, coverImage, outcomes }) =>
        outcomes.map((outcome) => ({ outcome, slug, title, coverImage }))
      )
      .flat()
      .sort((a, b) => {
        return a.outcome.order - b.outcome.order;
      });

    setOutcomeArray([...outcomeItems]);
  }, [publishedProjects]);

  const recalculateImageSize = () => {
    if (!square.current) return;
    setImageWidth((square?.current?.offsetWidth * 2) / 3);
  };

  const showImage = (e) => {
    setX(e.pageX + 5);
    setY(e.pageY + 5);
  };

  useEffect(() => {
    recalculateImageSize();
  }, [outcomeArray]);

  const loadMore = () => {
    setOutcomeArray(outcomeArray.concat(outcomeArray));
  };

  window.addEventListener("resize", recalculateImageSize);
  if (!outcomeArray.length)
    return (
      <>
        <Helmet>
          <title>Outcomes</title>
        </Helmet>
        <div></div>
      </>
    );
  return (
    <>
      <Helmet>
        <title>Outcomes</title>
      </Helmet>
      {isMobile ? (
        <OutcomeSwiper items={outcomeArray} />
      ) : (
        <InfiniteScroll
          dataLength={outcomeArray.length}
          next={() => loadMore()}
          hasMore={true}
          loader={<h4>Loading...</h4>}
          className="md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-8 xl:gap-10"
        >
          {outcomeArray?.map((outcome, index) => {
            return (
              <Link
                to={`/projects/${outcome.slug}`}
                key={index}
                className="group aspect-video pt-8 duration-300"
                onMouseMove={(e) => showImage(e)}
                ref={square}
              >
                <div className="text-grey3 lg:text-l5 lg:mb-6">
                  {outcome.title}
                </div>
                <div className="md:text-l3 group-hover:text-grey3 duration-300">
                  {outcome.outcome.value}
                </div>
                <div className="text-s6 flex items-center mt-4 ">
                  <span className="group-hover:text-grey3 duration-300">
                    {outcome.outcome.readMore}
                  </span>
                  <i className="fa-solid fa-arrow-right ml-1 group-hover:text-grey3 group-hover:ml-2 duration-300"></i>
                </div>
                <img
                  src={`/files/${outcome.coverImage}/download`}
                  className="opacity-0 rounded absolute group-hover:opacity-100 outcome-image"
                  style={{ top: y, left: x, width: imageWidth }}
                  alt={outcome.title}
                />
              </Link>
            );
          })}
        </InfiniteScroll>
      )}
    </>
  );
};

export default Outcomes;
