import React, { useEffect } from "react";
import { useArticles } from "../context/ArticlesContext";
import { useNavigate } from "react-router-dom";
import CircleLink from "../components/CircleLink";
import { Link } from "react-router-dom";

const Admin = () => {
  const navigate = useNavigate();
  let { articles } = useArticles();
  if (!articles) {
    return <div>Fetching articles</div>;
  }

  return (
    <div className="grid grid-cols-12 grid-rows-auto text-l7 min-h-screen">
      <div className="h-fit col-span-8 col-start-3 bg-white p-4 mb-10">
        <div className="grid grid-cols-8 pb-5 gap-4 items-center">
          <div className="col-span-4 pl-5">Headline</div>
          <div className="m-auto text-center col-span-5">Status</div>
        </div>
        {!articles.length ? (
          <div className="text-l5 pt-5">
            Hi James, here you can upload your first blog post. Hit the + button
            in the bottom right corner.
          </div>
        ) : (
          articles?.map((article) => {
            return (
              <div
                key={article._id}
                className="grid grid-cols-8 py-3 gap-4 items-center bg-warmGrey mb-4"
              >
                <Link
                  to={`/admin/articles/${article._id}`}
                  className="col-span-4 pl-5 text-l5"
                >
                  {article.headline}
                </Link>

                <div className="col-span-1 flex">
                  <input
                    checked={article.published}
                    className="form-check-input appearance-none h-6 w-6 rounded-full bg-red-600 checked:bg-green-600 disabled:cursor-not-allowed m-auto"
                    type="checkbox"
                    disabled={true}
                  />
                </div>
                <div className="col-span-2 flex">
                  {article.published
                    ? "ARTICLE PUBLISHED"
                    : "ARTICLE NOT PUBLISHED"}
                </div>

                <CircleLink
                  path={`/admin/articles/${article._id}`}
                  className="w-10 h-10 leading-10 text-s5 m-auto col-span-1"
                >
                  <i className="fa-solid fa-pen"></i>
                </CircleLink>
              </div>
            );
          })
        )}
      </div>

      <CircleLink
        path="/admin/reorder"
        className="fixed bottom-10 left-10 w-14 h-14 leading-14 text-s3"
      >
        <i className="fa-solid fa-arrows-rotate"></i>
      </CircleLink>
      <CircleLink
        path="/admin/new-article"
        className="fixed bottom-10 right-10 w-14 h-14 leading-14 text-s3"
      >
        <i className="fa-solid fa-plus"></i>
      </CircleLink>
    </div>
  );
};

export default Admin;
